import React from 'react'
import ContentEditable from 'react-contenteditable'

class BodyEditor extends React.Component {
    constructor(props) {
        super(props)
        let currentHTML = this.props.value
        this.contentEditable = React.createRef()
        this.state = {
            html: currentHTML,
            isModalOpen: false,
            caption: '',
            width: '',
            target: null,
        }
    }

    insertAfter = (element, referenceNode) => {
        referenceNode.parentNode.insertBefore(
            element,
            referenceNode.nextSibling
        )
    }

    componentDidMount() {
        var editor = document.getElementById('posteditor')

        editor.addEventListener('click', (event) => {
            let e = event.target.localName

            if (e === 'img') {
                if (
                    document.getElementById(
                        `caption-${event.target.currentSrc}`
                    )
                ) {
                    const currentCaption = document.getElementById(
                        `caption-${event.target.currentSrc}`
                    ).innerText
                    const currentImgWidth =
                        event.target.style.width ||
                        event.target.clientWidth + 'px'

                    this.setState({
                        isModalOpen: true,
                        caption: currentCaption,
                        width: currentImgWidth,
                        target: event.target,
                    })
                } else {
                    alert(
                        "this pre-update image doesn't support captions. Please remove and re-add same image to add captions."
                    )
                }

                // let caption = window.prompt('caption this photo');

                // if(caption) {
                // 	var newEl = document.createElement('p');
                // 	newEl.innerHTML = `<figcaption class="text-center text-sm italic text-gray-600 pb-4">${caption}</figcaption><div></br></div>`;

                // 	this.insertAfter(newEl,event.target);
                // }
            }
        })

        // editor.addEventListener('mouseout', (event) => {
        // 	let e = event.target.localName
        // 	if(e === 'img'){
        // 		// let width = window.prompt('how wide do you want to make this image? This is percentage or pixel based. For example, 200px, 50%, 100%')
        // 		// event.target.style.width = width
        // 		let imgtoolbar = document.getElementById('imgedit');

        // 		imgtoolbar.remove()

        // 		// let caption = window.prompt('caption this photo');

        // 		// if(caption) {
        // 		// 	var newEl = document.createElement('p');
        // 		// 	newEl.innerHTML = `<figcaption class="text-center text-sm italic text-gray-600 -mt-4 pb-4">${caption}</figcaption><div></br></div>`;

        // 		// 	this.insertAfter(newEl,event.target);
        // 		// }

        // 	}
        // });

        // editor.addEventListener('keypress', (event) => {
        // 	if(event.keyCode === 13) {
        // 		event.preventDefault();
        //   		document.execCommand('insertHTML', false, '<br></br><p></p>');
        // 		// var newEl = document.createElement('p');
        // 		// newEl.innerText = 'test';
        // 		// this.insertAfter(newEl,editor);
        // 	} else {
        // 		return false
        // 	}
        // })
    }

    // handleChange = (event) => {
    // 	const { name, value } = event.target;
    // 	const { editingMenuItem } = this.state;
    // 	editingMenuItem[name] = value
    // 	this.setState({
    // 	  editingMenuItem
    // 	})
    // }

    handleSubmit = (event) => {
        event.preventDefault()

        const newCaption = event.target.caption.value
        const newWidth = event.target.width.value

        // update caption
        document.getElementById(
            `caption-${this.state.target.currentSrc}`
        ).innerText = newCaption

        // update width
        document.getElementById(
            this.state.target.currentSrc
        ).style.width = newWidth

        const newHTML = document.getElementById('posteditor')
            .innerHTML

        this.handleChange({
            target: {
                value: newHTML,
            },
        })

        this.setState({
            isModalOpen: false,
            target: null,
        })
    }

    componentWillUnmount() {
        var editor = document.getElementById('posteditor')
        editor.removeEventListener('click', null, false)
        editor.removeEventListener('keypress', null, false)
    }

    handleChange = (evt) => {
        this.setState({
            html: evt.target.value,
        })
        this.props.handleBodyChange(evt.target.value)
    }

    render() {
        return (
            <div>
                <div style={{ minHeight: '55rem' }}>
                    {this.state.isModalOpen && (
                        <div
                            style={{ background: 'rgba(0,0,0,0.5)' }}
                            className="w-screen h-screen fixed z-40 flex items-center justify-center top-0 left-0"
                        >
                            <form
                                onSubmit={this.handleSubmit}
                                className="max-w-md container bg-white rounded shadow-lg p-4"
                            >
                                <div className="uppercase text-gray-500 text-sm font-semibold">
                                    Caption
                                </div>
                                <div className="mb-4">
                                    <input
                                        defaultValue={
                                            this.state.caption
                                        }
                                        className="border-2 border-gray-400 focus:border-blue-500 rounded px-3 py-1 outline-none w-full"
                                        name="caption"
                                    />
                                </div>
                                <div className="uppercase text-gray-500 text-sm font-semibold">
                                    Width (must be in pixels or
                                    percentage)
                                </div>
                                <div className="mb-4">
                                    <input
                                        defaultValue={
                                            this.state.width
                                        }
                                        className="border-2 border-gray-400 focus:border-blue-500 rounded px-3 py-1 outline-none w-full"
                                        name="width"
                                    />
                                </div>
                                <button
                                    type="submit"
                                    className="border-2 border-green-500 text-green-500 hover:bg-green-500 hover:text-white px-3 py-1 rounded cursor-pointer text-center"
                                >
                                    Done
                                </button>
                            </form>
                        </div>
                    )}
                    <ContentEditable
                        placeholder="Enter text here..."
                        id="posteditor"
                        innerRef={this.contentEditable}
                        html={this.state.html} // innerHTML of the editable div
                        disabled={false} // use true to disable editing
                        onChange={this.handleChange} // handle innerHTML change
                        tagName="article" // Use a custom HTML tag (uses a div by default)
                        className={this.props.className}
                    />
                </div>
            </div>
        )
    }
}

const ImageButton = (props) => {
    return (
        <button
            onMouseDown={(evt) => {
                evt.preventDefault() // Avoids loosing focus from the editable area
                let imgurl = window.prompt('Image url')
                document.execCommand('insertImage', false, imgurl) // Send the command to the browser
            }}
        >
            Image
        </button>
    )
}

const EditButton = (props) => {
    return (
        <button
            key={props.cmd}
            onMouseDown={(evt) => {
                evt.preventDefault() // Avoids loosing focus from the editable area
                document.execCommand(props.cmd, false, props.arg) // Send the command to the browser
            }}
        >
            {props.name || props.cmd}
        </button>
    )
}

export default BodyEditor
