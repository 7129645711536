import React from 'react'
import Layout from '../../components/Layout'
import {
    getPost,
    updatePost,
    deletePost,
    set_currently_editing,
} from '../../api/posts'
import { getLocations, getAd } from '../../api/locations'
import { getCategories } from '../../api/categories'
import config from '../../config'
import SiteCheckboxes from '../../components/SiteCheckboxes'
import DateTimePicker from 'react-datetime-picker'
import BodyEditor from '../../components/BodyEditor'
import Button from '../../components/ui/Button'
import ImagePickerModal from '../../components/ImagePickerModal'
import { Redirect, Prompt } from 'react-router-dom'
import CategoryCheckboxes from '../../components/CategoryCheckboxes'
import ReactQuill from 'react-quill' // ES6
import 'react-quill/dist/quill.snow.css' // ES6
import Quill from 'quill'
import buildHtml from '../../utils/buildHtml'
import { connect } from 'react-redux'
import CurrentlyEditing from '../../components/CurrentlyEditing'
//----
import Editor from '../../components/Editor'
//----
import createLog from '../../utils/logging.js'

console.log(Quill.imports)

let BlockEmbed = Quill.import('blots/block/embed')
class TweetBlot extends BlockEmbed {
    static create(id) {
        let node = super.create()
        node.dataset.id = id
        window.twttr.widgets.createTweet(id, node)
        return node
    }

    static value(domNode) {
        return domNode.dataset.id
    }
}

TweetBlot.blotName = 'tweet'
TweetBlot.tagName = 'div'
TweetBlot.className = 'tweet'

// Register quill modules
// ReactQuill.register('modules/imageDrop', ImageDrop)
// ReactQuill.register('modules/imageResize', ImageResize)
Quill.register(TweetBlot)

var autosavetimer

class Post extends React.Component {
    constructor(props) {
        super(props)
        this.quillRef = React.createRef()
        this.state = {
            site: props.match.params.id,
            postId: props.match.params.postId,
            post: {},
            adLocations: [],
            categories: [],
            isLoading: true,
            editingCode: false,
            imagePicker: false,
            featuredImagePicker: false,
            toPosts: false,
            isSaving: false,
            blockNavigation: false,
            successfulSave: false,
            autoSave: true,
        }
    }

    // modules = {
    //     toolbar: [
    //       [{ 'header': [1, 2, false] }],
    //       ['bold', 'italic', 'underline','strike', 'blockquote'],
    //       [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
    //       ['link', 'image'],
    //       ['clean']
    //     ],
    //   }

    //   formats = [
    //     'header',
    //     'bold', 'italic', 'underline', 'strike', 'blockquote',
    //     'list', 'bullet', 'indent',
    //     'link', 'image'
    //   ]

    componentDidMount() {
        // this.startAutoSaveCountdown()

        getPost(this.state.postId).then((post) => {
            console.log(
                `editor >>> ${
                    post.data.editor === undefined
                        ? 'v1'
                        : post.data.editor === 'v1'
                        ? 'v1'
                        : 'v2'
                }`
            )

            this.setState({
                isLoading: false,
                post: {
                    ...post.data,
                    categories: Array.isArray(post.data.categories)
                        ? post.data.categories
                        : [],
                    editor:
                        post.data.editor === undefined
                            ? 'v1'
                            : post.data.editor,
                },
            })

            // if the post isn't being updated, update with new values
            if (post.data.editing_by === null) {
                console.log('THIS SHOULD FIRE')
                set_currently_editing(
                    post.data.id,
                    this.props.user.attributes.email
                )
                // this.setEditingByStatus(
                //     this.props.user.attributes.email
                // )
            }
        })

        getLocations({ site: this.state.site }).then(
            (adLocations) => {
                let allLocations = adLocations.data.concat(
                    config.staticAdLocations
                )

                this.setState({
                    adLocations: allLocations,
                })
            }
        )

        getCategories({ site: this.state.site }).then(
            (categories) => {
                let allCategories = categories.data.concat(
                    config.staticPostCategories
                )

                this.setState({
                    categories: allCategories,
                })
            }
        )
    }

    // startAutoSaveCountdown = () => {
    //     console.log('starting autosave countdown........')
    //     // 15 minutes is 900000
    //     setTimeout(() => {
    //         this.setState(
    //             {
    //                 autoSave: false,
    //             },
    //             () => {
    //                 console.log('AUTOSAVE IS OFF ==>')
    //             }
    //         )
    //     }, 900000)
    // }

    componentWillUnmount() {
        // user leaves post, update editing status
        // will not try to update if post was just deleted
        if (this.state.toPosts === false) {
            console.log('UPDATE EDITINGBY')

            set_currently_editing(this.state.post.id, null).then(
                () => {
                    console.log('editing updated for post...')
                }
            )

            // if (
            //     this.state.post.editing_by ===
            //     this.props.user.attributes.email
            // ) {
            //     this.setEditingByStatus(
            //         this.props.user.attributes.email
            //     )
            // }
        }
    }

    // setEditingByStatus = (value) => {
    //     console.log('setting editing status')

    //     let udpatedPost = {
    //         ...this.state.post,
    //         editing_by: value,
    //     }

    //     this.setState({
    //         post: udpatedPost,
    //     })

    //     updatePost(udpatedPost).then(() => {
    //         console.log('post editing_by is updated')
    //     })
    // }

    takeOverEditing = () => {
        console.log('TAKING OVER EDITING ===>')

        let updatedPost = this.state.post

        updatedPost.editing_by = this.props.user.attributes.email

        this.setState({
            updatedPost,
        })
    }

    getSite = () => {
        let site = config.sites.filter(
            (site) => site.value === this.state.site
        )
        return site[0].url
    }

    handleSave = () => {
        if (this.state.post.categories.length === 0) {
            window.alert(
                'Please select at least 1 category/categories. You can choose multiple. Single categories will be removed soon.'
            )
        } else if (
            this.state.post.title.toLowerCase() === 'untitled'
        ) {
            window.alert(
                'Please rename this post to something unique. Untitled posts will be periodically removed. To avoid accidental deletion, rename this appropriately.'
            )
        } else {
            this.setState({
                isSaving: true,
            })

            // createLog({
            //     email: this.props.user.attributes.email,
            //     event: 'post_update',
            //     description: `Post (${this.state.post.id}) was updated`
            // })

            this.save()
        }
    }

    save = () => {
        updatePost(this.state.post).then(() => {
            this.setState({
                isSaving: false,
                successfulSave: true,
                blockNavigation: false,
            })

            setTimeout(() => {
                this.setState({
                    successfulSave: false,
                })
            }, 2500)
        })
    }

    // save = () => {
    //     updatePost(this.state.post).then((post) => {
    //         console.log('in front end...')
    //         console.log(post)
    //         createLog({
    //             email: this.props.user.attributes.email,
    //             event: 'post_autosave',
    //             description: `Post (${this.state.postId}) was updated`,
    //         })

    //         if (autosave) {
    //             this.setState({
    //                 isSaving: false,
    //                 blockNavigation: false,
    //             })
    //         } else {
    //             this.setState({
    //                 successfulSave: true,
    //                 isSaving: false,
    //                 blockNavigation: false,
    //             })

    //             setTimeout(() => {
    //                 this.setState({
    //                     successfulSave: false,
    //                 })
    //             }, 500)
    //         }
    //     })
    // }

    handleRadioChange = (event) => {
        let { post } = this.state
        const { name, value } = event.target
        post[name] = JSON.parse(value)
        this.setState({
            post,
            blockNavigation: true,
        })
    }

    handleChange = (event) => {
        let { post } = this.state
        const { name, value } = event.target
        post[name] = value
        this.setState({
            post,
            blockNavigation: true,
        })
    }

    handleSitesChange = (event) => {
        const newSite = event.target.value
        var { post } = this.state

        if (post.sites.includes(newSite)) {
            // Remove site from array
            var index = post.sites.indexOf(newSite)
            if (index > -1) {
                post.sites.splice(index, 1)
            }
        } else {
            // Add site to array
            post.sites.push(newSite)
        }

        // Push new array to state
        this.setState({
            post,
            blockNavigation: true,
        })
    }

    handleCategoryChange = (event) => {
        const newCategory = event.target.value
        let { post } = this.state

        if (post.categories.includes(newCategory)) {
            // Remove site from array
            var index = post.categories.indexOf(newCategory)
            if (index > -1) {
                post.categories.splice(index, 1)
            }
        } else {
            // Add site to array
            post.categories.push(newCategory)
        }

        // Push new array to state
        this.setState({
            post,
            blockNavigation: true,
        })
    }

    handleDateChange = (date) => {
        let { post } = this.state
        post.published = date
        this.setState({
            post,
            blockNavigation: true,
        })
    }

    handleBodyChange = (html) => {
        let { post } = this.state
        post.body = html
        this.setState({
            post,
            blockNavigation: true,
        })
    }

    handleCodeChange = (event) => {
        let code = event.target.value
        let { post } = this.state
        post.body = code
        this.setState({
            post,
            blockNavigation: true,
        })
    }

    handleCodeEditorChange = () => {
        this.setState({
            editingCode: !this.state.editingCode,
        })
    }

    handleImageSelect = (url, thumbnail) => {
        this.setState({
            imagePicker: false,
        })

        const quill = document.getElementById('quilleditor')
            .lastElementChild.__quill

        console.log(quill)

        const cursorPosition = quill.selection.savedRange.index
        quill.clipboard.dangerouslyPasteHTML(
            cursorPosition,
            `<img id="${url}" src="${url}" tabindex="0"/>`
        )
        quill.pasteHTML(
            cursorPosition + 1,
            '<figcaption></figcaption>'
        )

        // const cursorPosition = this.quillRef.current.quill.getEditor().getSelection().index
        // //   this.quill.insertText(cursorPosition, "★")
        // this.quill.clipboard.dangerouslyPasteHTML(cursorPosition, `<img id="${url}" src="${url}" tabindex="0"/><figcaption id="caption-${url}" contenteditable="false" className="text-center text-sm italic text-gray-600 -mt-4 pb-4"></figcaption><p></br></p>`)
        // this.quill.setSelection(cursorPosition + 1)

        // document.execCommand('insertHTML', false, `<img id="${url}" src="${url}" tabindex="0"/><figcaption id="caption-${url}" contenteditable="false" className="text-center text-sm italic text-gray-600 -mt-4 pb-4"></figcaption><p></br></p>`); // Send the command to the browser
    }

    closeImagePicker = () => {
        this.setState({
            imagePicker: false,
        })
    }

    // handleFeaturedImagePicker = url => {
    //     this.setState({
    //         imagePicker: false
    //     })
    //     document.execCommand('insertImage', false, url); // Send the command to the browser
    // }

    handleFeaturedImagePicker = (image) => {
        console.log(image.url_thumbnail)

        let { post } = this.state

        post.featured_image = image.url

        this.setState({
            post: {
                ...post,
                featured_image_thumbnail:
                    image.url_thumbnail.length === 42
                        ? image.url
                        : image.url_thumbnail,
            },
            featuredImagePicker: false,
        })
    }

    handleMouseDownForImage = (event) => {
        event.preventDefault()

        if (document.queryCommandEnabled('insertImage')) {
            this.setState({
                imagePicker: true,
            })
        } else {
            alert(
                'Place your cursor where you want the image and then try again'
            )
        }
    }

    handleDelete = () => {
        const confirmMessage =
            'Are you sure you want to delete this post? This is irreversible'

        const approveDelete = window.confirm(confirmMessage)

        if (approveDelete) {
            deletePost(
                this.state.post,
                this.props.user.attributes.email
            ).then(() => {
                console.log('post deleted')

                this.setState({
                    toPosts: true,
                })
            })
        }
    }

    removeFeaturedImage = () => {
        const { post } = this.state
        post.featured_image = null
        post.featured_image_thumbnail = null
        this.setState({
            post,
            blockNavigation: true,
        })
    }

    backgroundImagePreview = () => {
        const backgroundImage =
            this.state.post.featured_image_thumbnail !== null
                ? this.state.post.featured_image_thumbnail
                : this.state.post.featured_image

        return {
            height: `8rem`,
            backgroundImage: `url(${backgroundImage})`,
            backgroundPosition: `top center`,
            backgroundRepeat: `no-repeat`,
            backgroundSize: `cover`,
        }
    }

    selectAllSites = () => {
        let { post } = this.state

        post.sites = []

        config.sites.forEach((site, index) => {
            post.sites.push(site.value)
        })

        // Push new array to state
        this.setState({
            post,
            blockNavigation: true,
        })
    }

    justifyText = () => {
        document.execCommand('formatblock', false, 'p')
        let selectedTag = window.getSelection().anchorNode.parentNode
        selectedTag.classList = 'ql-align-justify'
        console.log(
            document.getElementById('quilleditor').childNodes[1]
                .innerHTML
        )
        setTimeout(() => {
            this.handleBodyChange(
                document.getElementById('quilleditor').childNodes[1]
                    .innerHTML
            )
        }, 200)
    }

    centerText = () => {
        document.execCommand('formatblock', false, 'p')
        let selectedTag = window.getSelection().anchorNode.parentNode
        selectedTag.classList = 'ql-align-center'
        console.log(
            document.getElementById('quilleditor').childNodes[1]
                .innerHTML
        )
        setTimeout(() => {
            this.handleBodyChange(
                document.getElementById('quilleditor').childNodes[1]
                    .innerHTML
            )
        }, 200)
    }

    checkOldSites = () => {
        if (this.state.post.sites.includes('northplatte')) {
            return true
        } else if (this.state.post.sites.includes('stjoseph')) {
            return true
        } else if (this.state.post.sites.includes('panhandle')) {
            return true
        } else {
            return false
        }
    }

    publishAndSave = () => {
        let { post } = this.state
        post.status = 'published'

        if (this.state.post.categories.length === 0) {
            window.alert(
                'Please select at least 1 category/categories. You can choose multiple. Single categories will be removed soon.'
            )
        } else {
            this.setState(
                {
                    post,
                },
                () => {
                    this.handleSave()
                }
            )
        }
    }

    saveAtCurrentTime = () => {
        let { post } = this.state
        post.published = new Date()

        if (this.state.post.categories.length === 0) {
            window.alert(
                'Please select at least 1 category/categories. You can choose multiple. Single categories will be removed soon.'
            )
        } else {
            this.setState(
                {
                    post,
                },
                () => {
                    this.handleSave()
                }
            )
        }
    }

    autoSave = (blocks) => {
        // clear timeout if one is set
        // clearTimeout(autosavetimer)

        this.setState({
            blockNavigation: true,
        })

        // updated state
        const { post } = this.state
        post.blocks = JSON.stringify(blocks)
        post.body = buildHtml(blocks)
        this.setState({ post })

        // start the autosaver if story is in pending status & autosave state is true.
        // autosave will turn off after 15 minutes.
        // if (
        //     post.status === 'pending' &&
        //     this.state.autoSave === true
        // ) {
        //     autosavetimer = setTimeout(() => {
        //         console.log('autosaving...')
        //         this.save(true)
        //     }, 500)
        // }
    }

    getImagePreview = (post) => {
        if (post.featured_image_thumbnail !== null) {
            return post.featured_image_thumbnail
        } else {
            return post.featured_image
        }
    }

    isFeaturedImageSet = (post) => {
        if (
            post.featured_image_thumbnail !== null ||
            post.featured_image !== null
        ) {
            return true
        } else {
            return false
        }
    }

    render() {
        const {
            isLoading,
            post,
            adLocations,
            categories,
            imagePicker,
            toPosts,
            site,
            featuredImagePicker,
            isSaving,
            successfulSave,
        } = this.state

        if (toPosts) {
            return <Redirect to={`/dashboard/${site}/posts`} />
        }

        // If the post is currently being edited, but by someone other than the
        // editingBy email.
        if (
            post.editing_by &&
            post.editing_by !== this.props.user.attributes.email
        ) {
            return (
                <CurrentlyEditing
                    email={post.editing_by}
                    takeOverEditing={this.takeOverEditing}
                />
            )
        }

        return (
            <Layout>
                {imagePicker && (
                    <ImagePickerModal
                        closeImagePicker={this.closeImagePicker}
                        handleMediaSelect={this.handleImageSelect}
                    />
                )}
                {featuredImagePicker && (
                    <ImagePickerModal
                        closeImagePicker={this.closeImagePicker}
                        handleMediaSelect={
                            this.handleFeaturedImagePicker
                        }
                    />
                )}
                <div>
                    {isLoading && <div>Loading post...</div>}
                    {!isLoading && post && (
                        <div>
                            <Prompt
                                when={this.state.blockNavigation}
                                message={(location) =>
                                    `Are you sure you want to go to ${location.pathname} without saving your changes?`
                                }
                            />
                            <div className="mb-1">
                                <input
                                    ref="inputtitle"
                                    onFocus={() => {
                                        this.refs.inputtitle.select()
                                    }}
                                    name="title"
                                    value={post.title}
                                    onChange={this.handleChange}
                                    className="text-xl w-full border-gray-300 border-2 px-3 py-1 outline-none focus:border-blue-500 rounded"
                                    placeholder="title"
                                />
                            </div>
                            <div className="mb-4">
                                <span className="text-gray-700 uppercase mr-2">
                                    URL:
                                </span>
                                <a
                                    className="text-blue-600 hover:underline"
                                    target="_blank"
                                    href={`${this.getSite()}/posts/${
                                        post.id
                                    }`}
                                >
                                    {this.getSite()}/posts/{post.id}
                                </a>
                            </div>
                            <div className="flex mb-24">
                                <div className="w-2/3 pr-4">
                                    {post.editor === 'v1' && (
                                        <div className="sticky top-0 bg-blue-900 text-white rounded-t overflow-hidden z-30">
                                            <EditButton cmd="Italic" />
                                            <EditButton cmd="Bold" />
                                            <LinkButton />
                                            <button
                                                onMouseDown={
                                                    this.justifyText
                                                }
                                                className="py-3 px-4 text-blue-200 hover:text-white focus:outline-none"
                                                id="center-text-button"
                                            >
                                                <svg
                                                    className="w-5 h-5"
                                                    viewBox="0 0 24 24"
                                                    width="24"
                                                    height="24"
                                                    stroke="currentColor"
                                                    stroke-width="2"
                                                    fill="none"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                >
                                                    <path d="M17 10L3 10M21 6L3 6M21 14L3 14M17 18L3 18" />
                                                </svg>
                                            </button>
                                            <button
                                                onMouseDown={
                                                    this.centerText
                                                }
                                                className="py-3 px-4 text-blue-200 hover:text-white focus:outline-none"
                                                id="center-text-button"
                                            >
                                                <svg
                                                    className="w-5 h-5"
                                                    viewBox="0 0 24 24"
                                                    width="24"
                                                    height="24"
                                                    stroke="currentColor"
                                                    stroke-width="2"
                                                    fill="none"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                >
                                                    <path d="M18 10L6 10M21 6L3 6M21 14L3 14M18 18L6 18" />
                                                </svg>
                                            </button>
                                            <button
                                                className="py-3 px-4 text-blue-200 hover:text-white focus:outline-none"
                                                onMouseDown={(
                                                    event
                                                ) =>
                                                    this.handleMouseDownForImage(
                                                        event
                                                    )
                                                }
                                            >
                                                <svg
                                                    className="w-5 h-5"
                                                    viewBox="0 0 24 24"
                                                    width="24"
                                                    height="24"
                                                    stroke="currentColor"
                                                    stroke-width="2"
                                                    fill="none"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                >
                                                    <rect
                                                        x="3"
                                                        y="3"
                                                        width="18"
                                                        height="18"
                                                        rx="2"
                                                        ry="2"
                                                    ></rect>
                                                    <circle
                                                        cx="8.5"
                                                        cy="8.5"
                                                        r="1.5"
                                                    ></circle>
                                                    <polyline points="21 15 16 10 5 21"></polyline>
                                                </svg>
                                            </button>
                                            <button
                                                className=" py-3 px-4 text-blue-200 hover:text-white focus:outline-none"
                                                onClick={
                                                    this
                                                        .handleCodeEditorChange
                                                }
                                            >
                                                {this.state
                                                    .editingCode && (
                                                    <svg
                                                        className="w-5 h-5"
                                                        viewBox="0 0 24 24"
                                                        width="24"
                                                        height="24"
                                                        stroke="currentColor"
                                                        stroke-width="2"
                                                        fill="none"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                    >
                                                        <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z" />
                                                        <path d="M14 2L14 8 20 8M16 13L8 13M16 17L8 17M10 9L9 9 8 9" />
                                                    </svg>
                                                )}
                                                {!this.state
                                                    .editingCode && (
                                                    <svg
                                                        className="w-5 h-5"
                                                        viewBox="0 0 24 24"
                                                        width="24"
                                                        height="24"
                                                        stroke="currentColor"
                                                        stroke-width="2"
                                                        fill="none"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                    >
                                                        <path d="M16 18L22 12 16 6M8 6L2 12 8 18" />
                                                    </svg>
                                                )}
                                            </button>
                                            <EmbedTweet />
                                        </div>
                                    )}
                                    <div className="bg-white rounded shadow-md p-4">
                                        {post.editor === 'v2' && (
                                            <Editor
                                                blocks={post.blocks}
                                                autoSave={
                                                    this.autoSave
                                                }
                                            />
                                        )}
                                        {/* {
                                            post && post.editor === 'v1' && !this.state.editingCode &&
                                            <ReactQuill
                                                theme="snow"
                                                value={this.state.post.body}
                                                onChange={this.handleBodyChange}
                                                // modules={Post.modules}
                                                // formats={this.formats}
                                                className=""
                                                id="quilleditor"
                                                ref="quillRef"
                                            />
                                        } */}
                                        {post.editor &&
                                            post.editor === 'v1' &&
                                            !this.state
                                                .editingCode && (
                                                <div>
                                                    <ReactQuill
                                                        theme="snow"
                                                        value={
                                                            post.body ||
                                                            ''
                                                        }
                                                        onChange={
                                                            this
                                                                .handleBodyChange
                                                        }
                                                        // modules={Post.modules}
                                                        // formats={this.formats}
                                                        className=""
                                                        id="quilleditor"
                                                        ref="quillRef"
                                                    />
                                                </div>
                                            )}
                                        {/* {
                                        !post.body.editor && !this.state.editingCode &&
                                        <BodyEditor
                                            handleBodyChange={this.handleBodyChange}
                                            value={post.body}
                                            className="focus:outline-none leading-relaxed p-4 merriweather"
                                        />
                                        } */}
                                        {post.editor === 'v1' &&
                                            this.state
                                                .editingCode && (
                                                <div className="mb-12">
                                                    <textarea
                                                        className="w-full h-auto p-4 shadow-lg bg-gray-800 text-white font-mono focus:outline-none"
                                                        rows="40"
                                                        value={
                                                            post.body
                                                        }
                                                        onChange={
                                                            this
                                                                .handleCodeChange
                                                        }
                                                    />
                                                </div>
                                            )}
                                    </div>
                                </div>

                                <div className="w-1/3">
                                    {successfulSave && (
                                        <div className="w-full bg-green-500 text-white font-mono mb-4 rounded p-4">
                                            Update Successful!
                                        </div>
                                    )}
                                    <div className=" flex flex-col justify-between bg-white rounded shadow-md p-4">
                                        {this.state.post.status ===
                                            'pending' && (
                                            <div className="mb-4">
                                                <div
                                                    onClick={
                                                        this
                                                            .publishAndSave
                                                    }
                                                    className={`border-2 border-green-500 bg-green-500 hover:bg-green-600 hover:border-green-600 text-white px-3 py-1 rounded cursor-pointer block text-center`}
                                                >
                                                    {isSaving
                                                        ? 'Publishing...'
                                                        : 'Schedule & Publish'}
                                                </div>
                                            </div>
                                        )}

                                        <div className="mb-4">
                                            <div
                                                onClick={
                                                    this.handleSave
                                                }
                                                className={`border-2 border-blue-500 bg-blue-500 hover:bg-blue-600 hover:border-blue-600 text-white px-3 py-1 rounded cursor-pointer block text-center`}
                                            >
                                                {isSaving
                                                    ? 'Saving...'
                                                    : this.state.post
                                                          .status ===
                                                      'pending'
                                                    ? 'Save Draft'
                                                    : 'Save'}
                                            </div>
                                        </div>

                                        {this.state.post.status !==
                                            'pending' && (
                                            <div className="mb-4">
                                                <div
                                                    onClick={
                                                        this
                                                            .saveAtCurrentTime
                                                    }
                                                    className={`border-2 border-green-500 bg-green-500 hover:bg-green-600 hover:border-green-600 text-white px-3 py-1 rounded cursor-pointer block text-center text-sm`}
                                                >
                                                    {isSaving
                                                        ? 'Saving...'
                                                        : 'Save & update published to now'}
                                                </div>
                                            </div>
                                        )}

                                        <div className="my-12">
                                            <div className="text-sm uppercase text-gray-700 mb-1">
                                                Story Preview
                                            </div>
                                            <div>
                                                <div className="flex">
                                                    <div
                                                        className="bg-gray-200 w-1/3"
                                                        style={this.backgroundImagePreview()}
                                                    ></div>
                                                    <div className="flex flex-col justify-between px-2 w-2/3 relative">
                                                        <div
                                                            className="merriweather text-xl leading-tight font-bold text-blue-900 overflow-hidden "
                                                            style={{
                                                                height:
                                                                    '8rem',
                                                            }}
                                                        >
                                                            {
                                                                post.title
                                                            }
                                                        </div>
                                                        <div
                                                            className="text-sm text-gray-600 italic font-sans absolute bottom-0 w-full h-5 flex flex-col justify-end pt-16"
                                                            style={{
                                                                background:
                                                                    'rgb(255,255,255',
                                                                background:
                                                                    'linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 72%)',
                                                            }}
                                                        >
                                                            Posted
                                                            example
                                                            ago
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="mb-4">
                                            <div className="text-sm uppercase text-gray-700">
                                                Featured Post?
                                            </div>
                                            <div>
                                                No
                                                <input
                                                    className="ml-1 mr-2"
                                                    name="featured"
                                                    type="radio"
                                                    value={false}
                                                    checked={
                                                        post.featured ===
                                                        true
                                                            ? false
                                                            : true
                                                    }
                                                    onChange={
                                                        this
                                                            .handleRadioChange
                                                    }
                                                />
                                                Yes
                                                <input
                                                    className="ml-1 mr-2"
                                                    name="featured"
                                                    type="radio"
                                                    value={true}
                                                    checked={
                                                        post.featured
                                                    }
                                                    onChange={
                                                        this
                                                            .handleRadioChange
                                                    }
                                                />
                                            </div>
                                        </div>

                                        <div className="mb-4">
                                            {/* {
                                                this.checkOldSites() && (
                                                    <div>
                                                        <div className="text-sm uppercase text-gray-700 mb-1">
                                                            Category (Please use both)
                                                        </div>
                                                        <select value={post.category} onChange={this.handleChange} name="category" className="block w-full">
                                                            <option>Select category</option>
                                                            {
                                                                categories.map((item, index) => {
                                                                    return (
                                                                        <option value={item.slug} key={index}>
                                                                            {item.title}
                                                                        </option>
                                                                    );
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                )
                                            } */}

                                            <div>
                                                <div className="mt-6">
                                                    <div className="text-sm uppercase text-gray-700 mb-1">
                                                        Categories
                                                    </div>
                                                    <CategoryCheckboxes
                                                        handleCategoryChange={
                                                            this
                                                                .handleCategoryChange
                                                        }
                                                        items={
                                                            this.state
                                                                .categories
                                                        }
                                                        categories={
                                                            post.categories
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="mb-4">
                                            <div className="text-sm uppercase text-gray-700">
                                                Status
                                            </div>
                                            <div>
                                                Pending
                                                <input
                                                    className="ml-1 mr-2"
                                                    name="status"
                                                    type="radio"
                                                    value="pending"
                                                    checked={
                                                        post.status ===
                                                        'pending'
                                                            ? true
                                                            : false
                                                    }
                                                    onChange={
                                                        this
                                                            .handleChange
                                                    }
                                                />
                                                Published
                                                <input
                                                    className="ml-1 mr-2"
                                                    name="status"
                                                    type="radio"
                                                    value="published"
                                                    checked={
                                                        post.status ===
                                                        'published'
                                                            ? true
                                                            : false
                                                    }
                                                    onChange={
                                                        this
                                                            .handleChange
                                                    }
                                                />
                                            </div>
                                        </div>

                                        <div className="mb-4">
                                            <div className="text-sm uppercase text-gray-700">
                                                Published
                                            </div>
                                            <div>
                                                <DateTimePicker
                                                    onChange={
                                                        this
                                                            .handleDateChange
                                                    }
                                                    value={
                                                        post.published
                                                            ? new Date(
                                                                  post.published
                                                              )
                                                            : new Date()
                                                    }
                                                />
                                            </div>
                                        </div>

                                        <div className="mb-4">
                                            <div className="text-sm uppercase text-gray-700 mb-1">
                                                Sidebar
                                            </div>
                                            <div>
                                                <select
                                                    className="block w-full"
                                                    value={
                                                        post.ad_sidebar_location
                                                    }
                                                    onChange={
                                                        this
                                                            .handleChange
                                                    }
                                                    name="ad_sidebar_location"
                                                >
                                                    {adLocations.map(
                                                        (
                                                            item,
                                                            index
                                                        ) => {
                                                            return (
                                                                <option
                                                                    value={
                                                                        item.slug
                                                                    }
                                                                    key={
                                                                        index
                                                                    }
                                                                >
                                                                    {
                                                                        item.title
                                                                    }
                                                                </option>
                                                            )
                                                        }
                                                    )}
                                                </select>
                                            </div>
                                        </div>

                                        <div className="mb-4">
                                            <div className="text-sm uppercase text-gray-700 mb-1">
                                                Featured Image
                                            </div>
                                            <div className="mb-2">
                                                <img
                                                    src={this.getImagePreview(
                                                        post
                                                    )}
                                                    className="object-fit rounded overflow-auto"
                                                />
                                            </div>
                                            {!this.isFeaturedImageSet(
                                                post
                                            ) && (
                                                <div
                                                    onClick={() =>
                                                        this.setState(
                                                            {
                                                                featuredImagePicker: true,
                                                            }
                                                        )
                                                    }
                                                    className={`border-2 border-teal-500 bg-white hover:text-white hover:bg-teal-600 hover:border-teal-600 text-teal-500 px-3 py-1 rounded cursor-pointer block text-center`}
                                                >
                                                    Add featured image
                                                </div>
                                            )}
                                            {this.isFeaturedImageSet(
                                                post
                                            ) && (
                                                <div
                                                    onClick={
                                                        this
                                                            .removeFeaturedImage
                                                    }
                                                    className={`border-2 border-red-500 bg-white hover:text-white hover:bg-red-600 hover:border-red-600 text-red-500 px-3 py-1 rounded cursor-pointer block text-center`}
                                                >
                                                    Remove featured
                                                    image
                                                </div>
                                            )}
                                        </div>

                                        <div className="mb-4">
                                            <div className="flex justify-between items-center">
                                                <div className="text-sm uppercase text-gray-700">
                                                    Sites
                                                </div>
                                                <div>
                                                    <button
                                                        className="text-sm bg-gray-200 px-3 py-1 rounded-full hover:bg-gray-300"
                                                        onClick={
                                                            this
                                                                .selectAllSites
                                                        }
                                                    >
                                                        Select All
                                                    </button>
                                                </div>
                                            </div>
                                            <div>
                                                <SiteCheckboxes
                                                    handleSitesChange={
                                                        this
                                                            .handleSitesChange
                                                    }
                                                    default={
                                                        this.state
                                                            .site
                                                    }
                                                    items={
                                                        config.sites
                                                    }
                                                    sites={post.sites}
                                                />
                                            </div>
                                        </div>
                                        <div
                                            onClick={
                                                this.handleDelete
                                            }
                                            className={`border-2 border-red-500 bg-red-500 hover:bg-red-600 hover:border-red-600 text-white px-3 py-1 rounded cursor-pointer block text-center mt-12`}
                                        >
                                            Delete Post
                                        </div>
                                    </div>
                                    {/* <div>
                                    <div>
                                        Allow Comments? {JSON.stringify(post.allowComments)}
                                    </div>
                                    <div>
                                        No
                                        <input
                                            name="allowComments"
                                            type="radio"
                                            value={false}
                                            checked={post.allowComments ? false : true}
                                            onChange={this.handleRadioChange}
                                        />
                                        Yes
                                        <input
                                            name="allowComments"
                                            type="radio"
                                            value={true}
                                            checked={post.allowComments}
                                            onChange={this.handleRadioChange}
                                        />
                                    </div>
                                </div> */}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </Layout>
        )
    }
}

const LinkButton = (props) => {
    return (
        <button
            className=" py-3 px-4 text-blue-200 hover:text-white focus:outline-none"
            onMouseDown={(evt) => {
                evt.preventDefault() // Avoids loosing focus from the editable area
                let linkUrl = window.prompt('URL')
                document.execCommand('CreateLink', false, linkUrl) // Send the command to the browser
            }}
        >
            <svg
                className="w-5 h-5"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
            >
                <path d="M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71" />
                <path d="M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71" />
            </svg>
        </button>
    )
}

const EditButton = (props) => {
    return (
        <button
            className="py-3 px-4 text-blue-200 hover:text-white focus:outline-none"
            key={props.cmd}
            onMouseDown={(evt) => {
                console.log(document)
                evt.preventDefault() // Avoids loosing focus from the editable area
                document.execCommand(props.cmd, false, props.arg) // Send the command to the browser
            }}
        >
            {props.cmd === 'Bold' && (
                <svg
                    className="w-5 h-5"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                >
                    <path d="M6 4h8a4 4 0 014 4 4 4 0 01-4 4H6zM6 12h9a4 4 0 014 4 4 4 0 01-4 4H6z" />
                </svg>
            )}
            {props.cmd === 'Italic' && (
                <svg
                    className="w-5 h-5"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                >
                    <path d="M19 4L10 4M14 20L5 20M15 4L9 20" />
                </svg>
            )}
        </button>
    )
}

const EmbedTweet = (props) => {
    return (
        <button
            className=" py-3 px-4 text-blue-200 hover:text-white focus:outline-none"
            key={'insertHTML'}
            onMouseDown={(evt) => {
                evt.preventDefault() // Avoids loosing focus from the editable area
                //   let tweetURL = window.prompt('Tweet Embed Code');
                //   document.execCommand('insertHTML', false, '<div className="tweet" data-id="20"></div>'); // Send the command to the browser
                //   window.twttr.widgets.createTweet(
                //     '20',
                //     document.getElementById('twitter-embed'),
                //     {
                //       theme: 'dark'
                //     }
                //   );

                var tweetURL = prompt('Tweet Link', '')
                if (tweetURL) {
                    const quill = document.getElementById(
                        'quilleditor'
                    ).lastElementChild.__quill

                    console.log(quill)

                    const cursorPosition =
                        quill.selection.savedRange.index

                    //   let range = this.$refs.myQuillEditor.quill.selection.savedRange.index
                    let id = tweetURL.substr(
                        tweetURL.lastIndexOf('/') + 1
                    )
                    quill.insertText(cursorPosition, '\n', 'user')
                    quill.insertEmbed(
                        cursorPosition + 1,
                        'tweet',
                        id,
                        'user'
                    )
                    quill.setSelection(cursorPosition + 2, 'user')
                }

                // const anchor = document.createElement("a");
                // anchor.setAttribute("class", "twitter-timeline");
                // anchor.setAttribute("data-theme", "dark");
                // anchor.setAttribute("data-tweet-limit", "5");
                // anchor.setAttribute("data-chrome", "noheader nofooter noborders");
                // anchor.setAttribute("href", tweetURL);
                // document.getElementsByClassName("twitter-embed")[0].appendChild(anchor);

                // const script = document.createElement("script");
                // script.setAttribute("src", "https://platform.twitter.com/widgets.js");
                // document.getElementsByClassName("twitter-embed")[0].appendChild(script);

                // TO DO : Add in a way to factory load tweets on mount. Tweets are frusterating
            }}
        >
            <svg
                className="w-5 h-5"
                viewBox="0 0 24 24"
                width="24"
                height="24"
                stroke="currentColor"
                stroke-width="2"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
            >
                <path d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z" />
            </svg>
        </button>
    )
}

/*
 * Quill modules to attach to editor
 * See http://quilljs.com/docs/modules/ for complete options
 */
// Post.modules = {
//     toolbar: {
//       container: "#toolbar",
//       handlers: {
//         "insertImage": insertImage,
//       }
//     }
//   }

/*
 * Quill editor formats
 * See http://quilljs.com/docs/formats/
 */
//   Post.formats = [
//     'header', 'font', 'size',
//     'bold', 'italic', 'underline', 'strike', 'blockquote',
//     'list', 'bullet', 'indent',
//     'link', 'image', 'color',
//   ]

const mapStateToProps = (state) => {
    return {
        user: state.user,
    }
}
export default connect(mapStateToProps)(Post)
