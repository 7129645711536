import React, { useState, useEffect } from 'react'
import { useParams, Link } from 'react-router-dom'
import Layout from '../../components/Layout'
import Upload from './Upload'
import { getMedia } from '../../api/media'
import config from '../../config'

const Media = () => {
    let { id: siteId } = useParams()
    const [site, setSite] = useState({})
    const [isLoading, setIsLoading] = useState(true)
    const [media, setMedia] = useState([])
    const [meta, setMeta] = useState({})
    const [searchQuery, setSearchQuery] = useState('')
    const [currentPage, setCurrentPage] = useState(null)

    useEffect(() => {
        getSite(siteId)

        fetchMediaItems({
            limit: 50,
        })
    }, [siteId])

    useEffect(() => {
        handleSearch()
    }, [currentPage])

    const getSite = (siteId) => {
        config.sites.find((site) => {
            if (site.value === siteId) {
                setSite(site)
            }
        })
    }

    const fetchMediaItems = async (query) => {
        try {
            const media = await getMedia(query)
            setMedia(media.data)
            setMeta(media.meta)
            setIsLoading(false)
        } catch (err) {
            console.log(err)
            alert(
                `Error, something went wrong getting media \n ${err}`
            )
        }
    }

    const handleSearchReturn = (event) => {
        let code = event.keyCode || event.which
        if (code === 13) {
            handleSearch()
        }
    }

    const handleSearch = () => {
        setIsLoading(true)

        /**
         * reset media
         */
        setMedia([])

        /**
         * Create new search object to use with api
         */
        let search_query = {
            ...(searchQuery.length
                ? {
                      search: searchQuery,
                  }
                : null),
            ...(currentPage !== null ? { page: currentPage } : null),
        }

        /**
         * Fetches pages with search query
         */
        fetchMediaItems({
            ...search_query,
            limit: 50,
        })
    }

    return (
        <Layout>
            <div className="mb-12">
                <Upload
                    getMedia={() => fetchMediaItems({ limit: 50 })}
                />
                <div className="mt-12 mb-2 flex justify-between items-center">
                    <div>
                        <div className="text-2xl font-semibold">
                            Media Library
                        </div>
                        <div className="text-gray-700 text-sm">
                            Upload and manage media in your Library
                        </div>
                    </div>
                    <div className="flex items-center flex-row">
                        <input
                            defaultValue={searchQuery}
                            onChange={(event) =>
                                setSearchQuery(event.target.value)
                            }
                            placeholder="Search Media"
                            onKeyPress={handleSearchReturn}
                            className="border-2 border-gray-300 focus:border-blue-500 px-3 py-1 rounded outline-none w-full mr-2"
                        />
                        <div
                            onClick={handleSearch}
                            className={`border-2 border-blue-500 bg-blue-500 hover:bg-blue-600 hover:border-blue-600 inline-block text-white px-3 py-1 rounded cursor-pointer`}
                        >
                            Search
                        </div>
                    </div>
                </div>
                {isLoading && <div>Loading media...</div>}
                <div className="mb-4">
                    {!isLoading && (
                        <div className="flex flex-wrap">
                            {media.map((mediaItem) => (
                                <Link
                                    to={`/dashboard/${site.value}/media/${mediaItem.id}`}
                                    className="w-1/2 sm:w-1/2 md:w-1/3 lg:w-1/5 p-1 cursor-pointer"
                                >
                                    <img
                                        className="h-48 w-full object-cover rounded shadow overflow-auto"
                                        src={`${
                                            mediaItem.url_thumbnail
                                                .length === 42
                                                ? mediaItem.url
                                                : mediaItem.url_thumbnail
                                        }`}
                                    />
                                </Link>
                            ))}
                        </div>
                    )}
                </div>
                <Pagination
                    meta={meta}
                    setCurrentPage={setCurrentPage}
                />
            </div>
        </Layout>
    )
}

const Pagination = ({ meta, setCurrentPage }) => (
    <div className="flex justify-between items-center">
        <div>
            {meta.total_results} Results - Page {meta.current_page} of{' '}
            {meta.total_pages}
        </div>
        <div className="flex items-center">
            <div className="mr-4">
                <button
                    disabled={meta.current_page > 1 ? false : true}
                    onClick={() =>
                        setCurrentPage(meta.current_page - 1)
                    }
                    className={`px-3 py-2 bg-gray-300 rounded-lg hover:bg-gray-400 ${
                        meta.current_page > 1
                            ? null
                            : 'cursor-not-allowed opacity-50'
                    } `}
                >
                    Prev
                </button>
            </div>
            <div>
                <button
                    disabled={
                        meta.current_page === meta.total_pages
                            ? true
                            : false
                    }
                    onClick={() =>
                        setCurrentPage(meta.current_page + 1)
                    }
                    className={`px-3 py-2 bg-gray-300 rounded-lg hover:bg-gray-400 ${
                        meta.current_page === meta.total_pages
                            ? 'cursor-not-allowed opacity-50'
                            : null
                    } `}
                >
                    Next
                </button>
            </div>
        </div>
    </div>
)

export default Media
