import React, { useState, useEffect } from 'react'
import Layout from '../../components/Layout'
import { Link, Redirect, useParams } from 'react-router-dom'
import { getPosts, createPost } from '../../api/posts'
import Moment from 'react-moment'
import config from '../../config'
import { getCategories } from '../../api/categories'
import { useSelector } from 'react-redux'

const Posts = () => {
    const user = useSelector((state) => state.user)
    let { id: siteId } = useParams()
    const [site, setSite] = useState({})
    const [isLoading, setIsLoading] = useState(true)
    const [posts, setPosts] = useState([])
    const [meta, setMeta] = useState({})
    const [toPost, setToPost] = useState(false)
    const [newPostId, setNewPostId] = useState(null)
    const [searchQuery, setSearchQuery] = useState('')
    const [categories, setCategories] = useState([])
    const [filterCategory, setFilterCategory] = useState('')
    const [statusCategory, setStatusCategory] = useState('')
    const [currentPage, setCurrentPage] = useState(null)

    useEffect(() => {
        getSite(siteId)

        fetchCategories({
            site: siteId,
        })

        fetchPosts({
            limit: 50,
            sites: siteId,
        })
    }, [siteId])

    /**
     * if current page is changed then fire search
     */
    useEffect(() => {
        handleSearch()
    }, [currentPage])

    const getSite = (siteId) => {
        config.sites.find((site) => {
            if (site.value === siteId) {
                setSite(site)
            }
        })
    }

    const fetchPosts = async (query) => {
        try {
            const posts = await getPosts(query)
            setPosts(posts.data)
            setMeta(posts.meta)
            setIsLoading(false)
        } catch (err) {
            console.error(err)
            alert(
                'Error, something went wrong getting posts \n ' + err
            )
        }
    }

    const fetchCategories = async (query) => {
        try {
            const categories = await getCategories(query)

            /**
             * combine static categories with database categories
             */
            let allCategories = categories.data.concat(
                config.staticPostCategories
            )

            setCategories(allCategories)
        } catch (err) {
            console.error(err)
            alert('Error, something went wrong getting categories')
        }
    }

    const handleCreatePost = async () => {
        try {
            const newPost = await createPost(site.value)

            setNewPostId(newPost.data.id)
            setToPost(true)
        } catch (err) {
            console.error(err)
            alert('Error, something went wrong creating post')
        }
    }

    const handleSearchReturn = (event) => {
        let code = event.keyCode || event.which
        if (code === 13) {
            handleSearch()
        }
    }

    const buildStatusCategoryFilter = () => {
        if (statusCategory === '') {
            return null
        } else {
            if (statusCategory === 'scheduled') {
                return {
                    scheduled: true,
                    order: 'ASC',
                }
            } else {
                return {
                    status: statusCategory,
                }
            }
        }
    }

    const handleSearch = () => {
        setIsLoading(true)

        /**
         * reset posts
         */
        setPosts([])

        /**
         * Create new search object to use with api and qs
         */
        let search_query = {
            ...(searchQuery.length
                ? {
                      search: searchQuery,
                  }
                : null),
            ...(filterCategory !== ''
                ? {
                      categories: filterCategory,
                  }
                : null),
            ...buildStatusCategoryFilter(),
            ...(currentPage !== null ? { page: currentPage } : null),
        }

        /**
         * Fetches posts with search query
         */
        fetchPosts({
            ...search_query,
            limit: 50,
            sites: siteId,
        })
    }

    if (toPost) {
        return (
            <Redirect
                to={`/dashboard/${site.value}/posts/${newPostId}`}
            />
        )
    }

    return (
        <Layout>
            <div className="mb-12">
                <div className="flex justify-between items-center mb-4">
                    <div>
                        <div className="text-2xl font-semibold">
                            All Posts
                        </div>
                        <div className="text-gray-700 text-sm">
                            Manage all posts and search
                        </div>
                    </div>
                    <div>
                        <div
                            type="success"
                            onClick={() => handleCreatePost()}
                            className="flex flex-row items-center bg-green-500 hover:bg-green-600 rounded-lg cursor-pointer px-3 py-2 text-white"
                        >
                            <svg
                                className="w-6 h-6 mr-2"
                                width="24"
                                height="24"
                                stroke="currentColor"
                                strokeWidth="2"
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            >
                                <path d="M12 5L12 19M5 12L19 12" />
                            </svg>
                            <span>New Post</span>
                        </div>
                    </div>
                </div>

                <div className="flex flex-row flex-wrap justify-between items-end mb-6">
                    <div className="w-full md:w-4/5 flex flex-row flex-wrap mb-2 md:mb-0">
                        <div className="w-full md:w-1/4 pr-0 md:pr-2">
                            <label className="uppercase text-xs font-bold text-gray-700">
                                Category
                            </label>
                            <div className="relative">
                                <select
                                    value={filterCategory}
                                    onChange={(event) =>
                                        setFilterCategory(
                                            event.target.value
                                        )
                                    }
                                    className="block appearance-none w-full border-2 border-gray-300 focus:border-blue-500 px-3 py-1 rounded outline-none"
                                >
                                    <option defaultValue value={''}>
                                        All Categories
                                    </option>
                                    {categories &&
                                        categories.map(
                                            (item, index) => {
                                                return (
                                                    <option
                                                        value={
                                                            item.slug
                                                        }
                                                        key={index}
                                                    >
                                                        {item.title}
                                                    </option>
                                                )
                                            }
                                        )}
                                </select>
                                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                    <svg
                                        className="fill-current h-4 w-4"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                    >
                                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div className="w-full md:w-1/4 pr-0 md:pr-2">
                            <label className="uppercase text-xs font-bold text-gray-700">
                                Status
                            </label>
                            <div className="relative">
                                <select
                                    value={statusCategory}
                                    onChange={(event) =>
                                        setStatusCategory(
                                            event.target.value
                                        )
                                    }
                                    className="block appearance-none w-full border-2 border-gray-300 focus:border-blue-500 px-3 py-1 rounded outline-none"
                                >
                                    <option defaultValue value={''}>
                                        Any Status
                                    </option>
                                    <option value={'scheduled'}>
                                        Scheduled
                                    </option>
                                    <option value={'published'}>
                                        Published
                                    </option>
                                    <option value={'pending'}>
                                        Pending
                                    </option>
                                </select>
                                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                    <svg
                                        className="fill-current h-4 w-4"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                    >
                                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div className="w-full md:w-1/3">
                            <label className="uppercase text-xs font-bold text-gray-700">
                                Search
                            </label>
                            <input
                                defaultValue={searchQuery}
                                onChange={(event) =>
                                    setSearchQuery(event.target.value)
                                }
                                placeholder="Search Posts"
                                onKeyPress={handleSearchReturn}
                                className="border-2 border-gray-300 focus:border-blue-500 px-3 py-1 rounded outline-none w-full mr-2"
                            />
                        </div>
                    </div>
                    <div className="w-full md:w-1/5 flex justify-end">
                        <button
                            onClick={handleSearch}
                            className="bg-gray-300 hover:bg-gray-400 text-gray-900 px-3 py-2 rounded-lg cursor-pointer"
                        >
                            Update Filter
                        </button>
                    </div>
                </div>

                <div className="flex flex-wrap rounded overflow-hidden shadow mb-8 bg-white">
                    <div className="w-full p-4 flex flex-row justify-between font-bold border-b-2 border-gray-100">
                        <div className="w-1/2">Title</div>
                        <div className="w-1/2 flex justify-end">
                            Published
                        </div>
                    </div>
                    {isLoading && (
                        <div className="p-4 text-gray-800">
                            Loading...
                        </div>
                    )}
                    {!isLoading &&
                        posts &&
                        posts.map((post) => (
                            <Link
                                to={`/dashboard/${site.value}/posts/${post.id}`}
                                className={`w-full p-4 hover:bg-orange-200 cursor-pointer flex flex-row justify-between border-b border-gray-200`}
                                key={post.id}
                            >
                                <div className="w-1/2 truncate flex items-center">
                                    <div>
                                        <div className="text-gray-900 font-semibold antialiased">
                                            {post.title}
                                        </div>
                                        <div className="text-xs flex flex-row">
                                            {post.featured && (
                                                <span className="text-blue-500 mr-2 uppercase">
                                                    Featured Post
                                                </span>
                                            )}
                                            {post.editing_by &&
                                                post.editing_by !==
                                                    user.attributes
                                                        .email && (
                                                    <span className="text-red-400 flex flex-row items-center">
                                                        CURRENTLY
                                                        BEING EDITED (
                                                        {post.editing_by.toLowerCase()}
                                                        )
                                                    </span>
                                                )}
                                        </div>
                                    </div>
                                </div>
                                <div className="w-1/2 flex items-center justify-end truncate">
                                    <Moment format="LLLL">
                                        {post.published}
                                    </Moment>
                                    <div className="ml-4 h-4 w-4">
                                        {Date.parse(post.published) >
                                        Date.now() ? (
                                            <svg
                                                className="w-4 h-4 text-orange-500"
                                                viewBox="0 0 24 24"
                                                width="24"
                                                height="24"
                                                stroke="currentColor"
                                                strokeWidth="2"
                                                fill="none"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            >
                                                <circle
                                                    cx="12"
                                                    cy="12"
                                                    r="10"
                                                />
                                                <path d="M12 6L12 12 16 14" />
                                            </svg>
                                        ) : null}
                                    </div>
                                    {post.status === 'published' ? (
                                        <div className="ml-4 h-4 w-4">
                                            <svg
                                                className="w-4 h-4 text-green-500"
                                                viewBox="0 0 24 24"
                                                width="24"
                                                height="24"
                                                stroke="currentColor"
                                                strokeWidth="2"
                                                fill="none"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            >
                                                <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14" />
                                                <path d="M22 4L12 14.01 9 11.01" />
                                            </svg>
                                        </div>
                                    ) : (
                                        <div className="ml-4 h-4 w-4">
                                            <svg
                                                className="w-4 h-4 text-red-500"
                                                viewBox="0 0 24 24"
                                                width="24"
                                                height="24"
                                                stroke="currentColor"
                                                strokeWidth="2"
                                                fill="none"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            >
                                                <circle
                                                    cx="12"
                                                    cy="12"
                                                    r="10"
                                                />
                                                <path d="M15 9L9 15M9 9L15 15" />
                                            </svg>
                                        </div>
                                    )}
                                </div>
                            </Link>
                        ))}
                </div>
                <Pagination
                    meta={meta}
                    setCurrentPage={setCurrentPage}
                />
                <Legend />
            </div>
        </Layout>
    )
}

const Pagination = ({ meta, setCurrentPage }) => (
    <div className="flex justify-between items-center">
        <div>
            {meta.total_results} Results - Page {meta.current_page} of{' '}
            {meta.total_pages}
        </div>
        <div className="flex items-center">
            <div className="mr-4">
                <button
                    disabled={meta.current_page > 1 ? false : true}
                    onClick={() =>
                        setCurrentPage(meta.current_page - 1)
                    }
                    className={`px-3 py-2 bg-gray-300 rounded-lg hover:bg-gray-400 ${
                        meta.current_page > 1
                            ? null
                            : 'cursor-not-allowed opacity-50'
                    } `}
                >
                    Prev
                </button>
            </div>
            <div>
                <button
                    disabled={
                        meta.current_page === meta.total_pages
                            ? true
                            : false
                    }
                    onClick={() =>
                        setCurrentPage(meta.current_page + 1)
                    }
                    className={`px-3 py-2 bg-gray-300 rounded-lg hover:bg-gray-400 ${
                        meta.current_page === meta.total_pages
                            ? 'cursor-not-allowed opacity-50'
                            : null
                    } `}
                >
                    Next
                </button>
            </div>
        </div>
    </div>
)

const Legend = () => (
    <div>
        <div className="flex flex-row items-center mt-6">
            <div className="flex flex-row items-center mr-2">
                <div>
                    <div className="h-4 w-4 mr-1">
                        <svg
                            className="w-4 h-4 text-red-500"
                            viewBox="0 0 24 24"
                            width="24"
                            height="24"
                            stroke="currentColor"
                            strokeWidth="2"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        >
                            <circle cx="12" cy="12" r="10" />
                            <path d="M15 9L9 15M9 9L15 15" />
                        </svg>
                    </div>
                </div>
                <div className="mr-2 text-gray-700">Pending</div>
            </div>
            <div className="flex flex-row items-center mr-2">
                <div>
                    <div className="h-4 w-4 mr-1">
                        <svg
                            className="w-4 h-4 text-green-500"
                            viewBox="0 0 24 24"
                            width="24"
                            height="24"
                            stroke="currentColor"
                            strokeWidth="2"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        >
                            <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14" />
                            <path d="M22 4L12 14.01 9 11.01" />
                        </svg>
                    </div>
                </div>
                <div className="mr-2 text-gray-700">Published</div>
            </div>
            <div className="flex flex-row items-center mr-2">
                <div>
                    <div className="h-4 w-4 mr-1">
                        <svg
                            className="w-4 h-4 text-orange-500"
                            viewBox="0 0 24 24"
                            width="24"
                            height="24"
                            stroke="currentColor"
                            strokeWidth="2"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        >
                            <circle cx="12" cy="12" r="10" />
                            <path d="M12 6L12 12 16 14" />
                        </svg>
                    </div>
                </div>
                <div className="mr-2 text-gray-700">Scheduled</div>
            </div>
        </div>
    </div>
)

export default Posts
