import { UPDATE_USER, GET_USER, REMOVE_USER, SET_ADS, ADD_AD, REMOVE_AD, UPDATE_AD_ORDER, SET_LOCATIONS, SET_MENU, ADD_MENU_ITEM, REMOVE_MENU_ITEM, UPDATE_MENU_ORDER, ADD_MENU_SUBLINK, SET_IMAGE_PICKER, SET_EDITOR_API, SET_SELECTED_IMAGE, SET_USER, SET_MENU_CHANGES_FALSE, SET_MENU_CHANGES_TRUE } from '../actions/constants';

const initialState = {
    user: {},
    firebase: {},
    config: {},
    adlocations: {
        undefined: []
    },
    menu: {},
    imagePicker: false,
    editorAPI: {},
    selectedImage: null,
    menu_has_changes: false,
}

const rootReducer = (state = initialState, action) => {
    switch (action.type) {

        case (GET_USER):
            return state;

        case (SET_USER): {
            return {
                ...state,
                user: action.payload.user
            }
        }

        case (UPDATE_USER):
            return Object.assign({}, state, {
                user: action.payload.user,
                firebase: action.payload.firebase,
                config: action.payload.config
            });

        case (REMOVE_USER):
            return Object.assign({}, state, {
                user: null,
                firebase: null,
                config: null
            });

        case (SET_LOCATIONS):
            const locations = { ...state.adlocations }
            locations[action.payload.location] = []
            return Object.assign({}, state, {
                adlocations: locations
            })

        case (SET_MENU_CHANGES_FALSE): {
            return {
                ...state,
                menu_has_changes: false
            }
        }

        case (SET_MENU_CHANGES_TRUE): {
            console.log('firing hererererere')
            return {
                ...state,
                menu_has_changes: true
            }
        }

        case (SET_ADS):
            const ad_locations = { ...state.adlocations }
            if (ad_locations[action.payload.ad.location]) {
                ad_locations[action.payload.ad.location] = [...ad_locations[action.payload.ad.location].concat(action.payload.ad)]
            } else {
                ad_locations['undefined'] = [...ad_locations['undefined'].concat(action.payload.ad)]
            }

            return Object.assign({}, state, {
                adlocations: ad_locations
            });

        case (ADD_AD):

            const ad__locations = { ...state.adlocations }
            ad__locations[action.payload.ad.location] = [...ad__locations[action.payload.ad.location].concat(action.payload.ad)]

            return Object.assign({}, state, {
                adlocations: ad__locations
            });

        case (REMOVE_AD):
            let ad_____locations = { ...state.adlocations }

            ad_____locations[action.payload.ad.location] = [...ad_____locations[action.payload.ad.location].filter(ad => ad.id !== action.payload.ad.id)]

            return Object.assign({}, state, {
                adlocations: ad_____locations
            })

        case (UPDATE_AD_ORDER):
            const ad___locations = { ...state.adlocations }
            ad___locations[action.payload.location] = action.payload.ads;

            return Object.assign([], state, {
                adlocations: ad___locations
            });

        case (SET_MENU):

            return Object.assign({}, state, {
                menu: action.payload.menu
            });

        case (ADD_MENU_ITEM):

            let add_menu_item = { ...state.menu }
            add_menu_item.links = add_menu_item.links.concat(action.payload.newLink)

            return Object.assign({}, state, {
                menu: add_menu_item,
                menu_has_changes: true
            });

        case (ADD_MENU_SUBLINK):
            let add_sublink = { ...state.menu }
            add_sublink.links[action.payload.parentIndex].sublinks.concat(action.payload.newLink)

            return Object.assign({}, state, {
                menu: add_sublink,
                menu_has_changes: true
            })

        case (SET_IMAGE_PICKER): {
            return {
                ...state,
                imagePicker: action.payload.imagePicker
            }
        }

        case (SET_EDITOR_API): {
            return {
                ...state,
                editorAPI: action.payload.editorAPI
            }
        }

        case (SET_SELECTED_IMAGE): {
            return {
                ...state,
                selectedImage: action.payload.selectedImage
            }
        }

        default:
            return state;
    }
}

export default rootReducer