import API, { api_key } from './api'

export const getMedia = async (query) => {
    try {
        const response = await API.get(`/media`, {
            params: {
                ...query,
            },
        })

        return response.data
    } catch (err) {
        return {
            message: 'Error in media api helper',
            error: err,
        }
    }
}

export const getMediaItem = async (id) => {
    try {
        const response = await API.get(`/media/${id}`)

        return response.data
    } catch (err) {
        return {
            message: 'Error in get media item api helper',
            error: err,
        }
    }
}

export const createMediaItem = async (media) => {
    try {
        const response = await API.post(
            `/media`,
            {
                description: media.description,
                attachment: media.attachment,
                attachment_thumbnail: media.attachment_thumbnail,
                type: media.type,
            },
            {
                params: {
                    ...api_key,
                },
            }
        )

        return response.data
    } catch (err) {
        return {
            message: 'Error in create media api helper',
            error: err,
        }
    }
}

export const updateMediaItem = async (media) => {
    try {
        const response = await API.put(
            `/media/${media.id}`,
            {
                description: media.description,
            },
            {
                params: {
                    ...api_key,
                },
            }
        )

        return response.data
    } catch (err) {
        return {
            message: 'Error in update media api helper',
            error: err,
        }
    }
}

export const deleteMediaItem = async (media) => {
    try {
        const response = await API.delete(`/media/${media.id}`, {
            params: {
                ...api_key,
            },
        })

        return response.data
    } catch (err) {
        return {
            message: 'Error in delete media api helper',
            error: err,
        }
    }
}
