export const GET_USER = 'GET_USER'
export const UPDATE_USER = 'UPDATE_USER'
export const REMOVE_USER = 'REMOVE_USER'
export const SET_USER = 'SET_USER'

export const SET_LOCATIONS = 'SET_LOCATIONS';
export const SET_ADS = 'SET_ADS';
export const ADD_AD = 'ADD_AD';
export const REMOVE_AD = 'REMOVE_AD';
export const UPDATE_AD_ORDER = 'UPDATE_AD_ORDER';

export const SET_MENU = 'SET_MENU';
export const ADD_MENU_ITEM = 'ADD_MENU_ITEM';
export const ADD_MENU_SUBLINK = 'ADD_MENU_SUBLINK';
export const REMOVE_MENU_ITEM = 'REMOVE_MENU_ITEM';
export const UPDATE_MENU_ORDER = 'UPDATE_MENU_ORDER';
export const SET_MENU_CHANGES_FALSE = 'SET_MENU_CHANGES_FALSE';
export const SET_MENU_CHANGES_TRUE = 'SET_MENU_CHANGES_TRUE';

export const SET_EDITOR_API = 'SET_EDITOR_API'
export const SET_IMAGE_PICKER = 'SET_IMAGE_PICKER'
export const SET_SELECTED_IMAGE = 'SET_SELECTED_IMAGE'
