import React, { useState, useEffect } from 'react'
import API, { api_key } from '../../api/api'
import config from '../../config'
import { useSelector } from 'react-redux'

const Actions = () => {
    const user = useSelector((state) => state.user)

    return (
        <div className="bg-gray-900 text-gray-100 font-mono text-lg text-center max-w-3xl shadow-lg mx-auto my-12 rounded-lg p-3">
            <p>
                Updates Pending - Please check back soon
            </p>
        </div>
    )
}

export default Actions

// class Actions extends React.Component {
//     constructor(props) {
//         super(props)

//         this.state = {
//             site: 'northplatte',
//             allowedEmails: [
//                 'gbland@goeaglecom.net',
//                 'rfields@goeaglecom.net',
//                 'leslie.eikleberry@eagleradio.net',
//             ],
//             actions: [
//                 {
//                     id: 'show-arrests-90',
//                     title: 'Show Arrests (90)',
//                     description: `Show total number of arrest posts older than 90 days old`,
//                     running: false,
//                     log: [],
//                     action: (id) => this.show_arrests_90_days(id),
//                 },
//                 {
//                     id: 'delete-arrests-90',
//                     title: 'Delete Arrests (90)',
//                     description: `Delete arrest posts older than 90 days old`,
//                     running: false,
//                     log: [],
//                     action: (id) => this.delete_arrests_90_days(id),
//                 },
//             ],
//         }
//     }

//     show_arrests_90_days = async (id) => {
//         try {
//             let days = 90
//             let daysAgo = new Date(
//                 Date.now() - days * 24 * 60 * 60 * 1000
//             ).toISOString()
//             let access_token = 4

//             let actions = this.state.actions

//             var action = actions.find((action) => action.id === id)
//             action.running = true

//             action.log.push({
//                 message: 'Starting...',
//             })

//             this.setState({
//                 action,
//             })

//             const request = `/posts?filter[where][categories]=arrests&filter[where][published][lt]=${daysAgo}&filter[where][sites]=${this.state.site}`

//             action.log.push({
//                 message: `GET ${request}`,
//             })
//             this.setState({ action })

//             API.get(request + '&access_token=' + access_token).then(
//                 (response) => {
//                     action.log.push({
//                         message: `There are ${response.data.length} arrest posts in ${this.state.site} that are older than 90 days old`,
//                     })
//                     action.log.push({
//                         message: `Complete...`,
//                     })
//                     action.running = false
//                     this.setState({
//                         action,
//                     })
//                 }
//             )
//         } catch (err) {
//             return {
//                 message: 'Error in ads api helper',
//                 error: err,
//             }
//         }
//     }

//     delete_arrests_90_days = async (id) => {
//         try {
//             let days = 90
//             let daysAgo = new Date(
//                 Date.now() - days * 24 * 60 * 60 * 1000
//             ).toISOString()
//             let access_token = 4

//             let actions = this.state.actions

//             var action = actions.find((action) => action.id === id)
//             action.running = true

//             action.log.push({
//                 message: 'Starting...',
//             })

//             this.setState({
//                 action,
//             })

//             const request = `/posts?filter[where][categories]=arrests&filter[where][published][lt]=${daysAgo}&filter[where][sites]=${this.state.site}`

//             action.log.push({
//                 message: `DELETE ${request}`,
//             })
//             this.setState({ action })

//             let arrests = await API.get(
//                 request + '&access_token=' + access_token
//             ).then((response) => {
//                 return response.data
//             })

//             Promise.all(
//                 arrests.map((post) => {
//                     return new Promise((resolve, reject) => {
//                         API.delete(
//                             `/posts/${post.id}?access_token=${access_token}`
//                         ).then((data) => {
//                             resolve(data)
//                         })
//                     })
//                 })
//             ).then((values) => {
//                 console.log(values)
//                 action.log.push({
//                     message: `Deleted ${values.length} arrest posts older than 90 days old`,
//                 })
//                 action.log.push({
//                     message: `Complete...`,
//                 })
//                 action.running = false
//                 this.setState({
//                     action,
//                 })
//             })
//         } catch (err) {
//             alert('An error happened. Nothing was deleted.')
//             return
//         }
//     }

//     clearLog = (id) => {
//         let actions = this.state.actions
//         var action = actions.find((action) => action.id === id)
//         action.log = []
//         this.setState({
//             action,
//         })
//     }

//     handleChange = (event) => {
//         this.setState({
//             site: event.target.value,
//         })
//     }

//     render() {
//         const { actions, allowedEmails } = this.state
//         let { user } = this.props

//         if (user && user.attributes) {
//             if (allowedEmails.includes(user.attributes.email)) {
//                 // user is allowed to access
//                 return (
//                     <div>
//                         {user && user.attributes && (
//                             <div className="max-w-4xl mx-auto mb-24">
//                                 <div className="mt-16">
//                                     <div className="text-3xl font-bold flex items-center">
//                                         Administrative Actions for
//                                         <select
//                                             onChange={
//                                                 this.handleChange
//                                             }
//                                             className="bg-white px-6 shadow text-2xl ml-6"
//                                         >
//                                             {config.sites.map(
//                                                 (site) => (
//                                                     <option
//                                                         selected={
//                                                             site.value ===
//                                                             this.state
//                                                                 .site
//                                                                 ? true
//                                                                 : false
//                                                         }
//                                                         value={
//                                                             site.value
//                                                         }
//                                                     >
//                                                         {site.title}
//                                                     </option>
//                                                 )
//                                             )}
//                                         </select>
//                                     </div>
//                                     <div className="antialiased mt-2">
//                                         Please review each action
//                                         before running. This is
//                                         permanent and not reversible.
//                                     </div>
//                                 </div>
//                                 <div>
//                                     {actions.map((action) => (
//                                         <div
//                                             key={action.id}
//                                             className="shadow p-3 bg-white mt-8"
//                                         >
//                                             <div className="flex justify-between items-center">
//                                                 <div className="w-3/5">
//                                                     <div className="text-lg font-bold text-gray-800 leading-tight">
//                                                         {action.title}
//                                                     </div>
//                                                     <div class="leading-tight font-gray-600 antialiased">
//                                                         {
//                                                             action.description
//                                                         }
//                                                     </div>
//                                                 </div>
//                                                 <div>
//                                                     <button
//                                                         onClick={() =>
//                                                             action.action(
//                                                                 action.id
//                                                             )
//                                                         }
//                                                         className="flex flex-row items-center px-4 py-2 bg-blue-600 text-blue-100 hover:bg-blue-500 font-mono outline-none focus:outline-none"
//                                                     >
//                                                         <span className="mr-2">
//                                                             {action.running
//                                                                 ? 'Loading...'
//                                                                 : 'Execute'}
//                                                         </span>
//                                                         {!action.running && (
//                                                             <svg
//                                                                 className=""
//                                                                 viewBox="0 0 24 24"
//                                                                 width="24"
//                                                                 height="24"
//                                                                 stroke="currentColor"
//                                                                 stroke-width="1.5"
//                                                                 fill="none"
//                                                                 stroke-linecap="round"
//                                                                 stroke-linejoin="round"
//                                                             >
//                                                                 <circle
//                                                                     cx="12"
//                                                                     cy="12"
//                                                                     r="10"
//                                                                 ></circle>
//                                                                 <polygon points="10 8 16 12 10 16 10 8"></polygon>
//                                                             </svg>
//                                                         )}
//                                                     </button>
//                                                 </div>
//                                             </div>
//                                             <div
//                                                 className={`${
//                                                     action.log
//                                                         .length > 0
//                                                         ? 'bg-gray-900 text-white'
//                                                         : 'bg-gray-300 text-gray-500'
//                                                 } font-mono mt-4 py-1 px-2 overflow-x-scroll`}
//                                             >
//                                                 {action.log.map(
//                                                     (item, index) => (
//                                                         <div
//                                                             key={
//                                                                 index
//                                                             }
//                                                         >
//                                                             >{' '}
//                                                             {
//                                                                 item.message
//                                                             }
//                                                         </div>
//                                                     )
//                                                 )}
//                                                 {action.log.length ===
//                                                     0 && (
//                                                     <div>
//                                                         Action logs
//                                                         will appear
//                                                         here...
//                                                     </div>
//                                                 )}
//                                             </div>
//                                             {action.log.length !==
//                                                 0 && (
//                                                 <button
//                                                     onClick={() =>
//                                                         this.clearLog(
//                                                             action.id
//                                                         )
//                                                     }
//                                                     className="mt-2 px-2 py-px bg-gray-300 text-gray-600 text-sm hover:bg-gray-400 focus:outline-none flex items-center"
//                                                 >
//                                                     Clear Log
//                                                     <span>
//                                                         <svg
//                                                             className="w-4 h-4 text-red-500 ml-1"
//                                                             viewBox="0 0 24 24"
//                                                             width="24"
//                                                             height="24"
//                                                             stroke="currentColor"
//                                                             stroke-width="2"
//                                                             fill="none"
//                                                             stroke-linecap="round"
//                                                             stroke-linejoin="round"
//                                                         >
//                                                             <line
//                                                                 x1="18"
//                                                                 y1="6"
//                                                                 x2="6"
//                                                                 y2="18"
//                                                             ></line>
//                                                             <line
//                                                                 x1="6"
//                                                                 y1="6"
//                                                                 x2="18"
//                                                                 y2="18"
//                                                             ></line>
//                                                         </svg>
//                                                     </span>
//                                                 </button>
//                                             )}
//                                         </div>
//                                     ))}
//                                 </div>
//                             </div>
//                         )}
//                     </div>
//                 )
//             } else {
//                 return (
//                     <div class="p-4">
//                         Unauthorized. Not allowed to access this
//                         resource.
//                     </div>
//                 )
//             }
//         } else {
//             return <div>Loading...</div>
//         }
//     }
// }

// const mapStateToProps = (state) => {
//     return {
//         user: state.user,
//     }
// }
// export default connect(mapStateToProps)(Actions)
