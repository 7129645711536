import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import {
    SortableContainer,
    SortableElement,
    SortableHandle,
} from 'react-sortable-hoc'
import arrayMove from 'array-move'
import { connect } from 'react-redux'
import { SET_MENU, SET_MENU_CHANGES_TRUE } from '../actions/constants'
import SortableSubLinksList from './SortableSubLinksList'

class SortableLinksList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            site: props.match.params.id,
            menu: {},
            isLoading: true,
            isModalOpen: false,
            editingParentIndex: null,
            editingMenuItem: null,
        }
    }

    // saveMenu = () => {
    //   updateMenu(this.props.menu.id, this.props.menu).then(() => {
    //     console.log('menu updated');
    //   })
    // }

    onSortEnd = ({ oldIndex, newIndex }) => {
        const updatedMenu = { ...this.props.menu }
        updatedMenu.links = arrayMove(
            this.props.menu.links,
            oldIndex,
            newIndex
        )

        this.props.dispatch({
            type: SET_MENU,
            payload: {
                menu: updatedMenu,
            },
        })

        // updates save button status
        this.props.dispatch({
            type: SET_MENU_CHANGES_TRUE,
        })

        // updateMenu(this.props.menu.id, this.props.menu).then(() => {
        //   console.log('menu updated');
        // })
    }

    handleDelete = (index) => {
        const confirmMessage =
            'Are you sure you want to delete this parent item? This will delete children links. This cannot be reversed.'

        if (window.confirm(confirmMessage)) {
            let newMenu = { ...this.props.menu }
            newMenu.links.splice(index, 1)

            // removes item from array in redux store
            this.props.dispatch({
                type: SET_MENU,
                payload: {
                    menu: newMenu,
                },
            })

            // updates save button status
            this.props.dispatch({
                type: SET_MENU_CHANGES_TRUE,
            })

            // updateMenu(this.props.menu.id, this.props.menu).then(() => {
            //   console.log('menu updated');
            // })
        }
    }

    handleChange = (event) => {
        const { name, value } = event.target
        console.log(name)
        console.log(event.target)
    }

    toggleModal = (parentIndex = null, menuItem = null) => {
        this.setState((currentState) => {
            return {
                isModalOpen: !currentState.isModalOpen,
                editingParentIndex: parentIndex,
                editingMenuItem: menuItem,
            }
        })
    }

    handleMenuChange = (event) => {
        const { name, value } = event.target
        const { editingMenuItem } = this.state
        editingMenuItem[name] = value
        this.setState({
            editingMenuItem,
        })
    }

    handleParentLinkSave = () => {
        const updatedMenu = { ...this.props.menu }
        this.props.dispatch({
            type: SET_MENU,
            payload: {
                menu: updatedMenu,
            },
        })
        // updates save button status
        this.props.dispatch({
            type: SET_MENU_CHANGES_TRUE,
        })
        // updateMenu(this.props.menu.id, this.props.menu).then(() => {
        //   console.log('menu updated');
        // })
        this.toggleModal()
    }

    render() {
        return (
            <>
                {this.state.isModalOpen && (
                    <div
                        style={{ background: 'rgba(0,0,0,0.5)' }}
                        className="w-screen h-screen fixed z-40 flex items-center justify-center top-0 left-0"
                    >
                        <div className="max-w-md container bg-white rounded shadow-lg p-4">
                            <div className="uppercase text-gray-500 text-sm font-semibold">
                                Title
                            </div>
                            <div className="mb-4">
                                <input
                                    className="border-2 border-gray-400 focus:border-blue-500 rounded px-3 py-1 outline-none w-full"
                                    value={
                                        this.state.editingMenuItem
                                            .title
                                    }
                                    name="title"
                                    onChange={this.handleMenuChange}
                                />
                            </div>
                            <div className="uppercase text-gray-500 text-sm font-semibold">
                                Link
                            </div>
                            <div className="mb-4">
                                <input
                                    className="border-2 border-gray-400 focus:border-blue-500 rounded px-3 py-1 outline-none w-full"
                                    value={
                                        this.state.editingMenuItem
                                            .link
                                    }
                                    name="link"
                                    onChange={this.handleMenuChange}
                                />
                            </div>
                            {/* <div className="uppercase text-gray-500 text-sm font-semibold">
                  Open in new tab?
                </div>
                <div className="mb-4">
                  <input className="border-2 border-gray-400 focus:border-blue-500 rounded px-3 py-1 outline-none w-full" value={this.state.editingMenuItem.external} name="external" onChange={this.handleMenuChange}/>
                </div>
                <div className="uppercase text-gray-500 text-sm font-semibold">
                  Target
                </div>
                <div className="mb-4">
                  <input className="border-2 border-gray-400 focus:border-blue-500 rounded px-3 py-1 outline-none w-full" value={this.state.editingMenuItem.target} name="target" onChange={this.handleMenuChange}/>
                </div> */}
                            <div
                                onClick={this.handleParentLinkSave}
                                className="border-2 border-green-500 text-green-500 hover:bg-green-500 hover:text-white px-3 py-1 rounded cursor-pointer text-center"
                            >
                                Done
                            </div>
                        </div>
                    </div>
                )}
                <SortableParentList
                    items={this.props.menu.links}
                    onSortEnd={this.onSortEnd}
                    onChildSortEnd={this.onSortChildEnd}
                    useDragHandle={true}
                    handleChange={this.handleChange}
                    handleSubLinkChange={this.handleSubLinkChange}
                    newSubLink={this.newSubLink}
                    handleDelete={this.handleDelete}
                    toggleModal={this.toggleModal}
                />
            </>
        )
    }
}

const DragHandle = SortableHandle(() => (
    <svg
        className="w-5 h-5 text-gray-700 mr-4 cursor-move"
        viewBox="0 0 24 24"
        width="24"
        height="24"
        stroke="currentColor"
        strokeWidth="2"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
    >
        <path d="M5 9L2 12 5 15M9 5L12 2 15 5M15 19L12 22 9 19M19 9L22 12 19 15M2 12L22 12M12 2L12 22" />
    </svg>
))

const SortableParentList = SortableContainer((props) => {
    return (
        <div>
            {props.items.map((value, index) => {
                return (
                    <SortableParentItem
                        key={`item-${index}`}
                        index={index}
                        testIndex={index}
                        value={value}
                        handleChange={props.handleChange}
                        newSubLink={props.newSubLink}
                        handleSubLinkChange={
                            props.handleSubLinkChange
                        }
                        onChildSortEnd={props.onChildSortEnd}
                        handleDelete={props.handleDelete}
                        toggleModal={props.toggleModal}
                    />
                )
            })}
        </div>
    )
})

const SortableParentItem = SortableElement((props) => {
    return (
        <div className="w-full flex flex-wrap pb-8 items-center">
            <div className="flex w-full bg-white p-4 shadow rounded hover:bg-orange-100">
                <div className="flex flex-row w-full items-center justify-between">
                    <div className="flex flex-row items-center">
                        <DragHandle></DragHandle>
                        <div className="">{props.value.title}</div>
                    </div>
                    <div className="flex items-center">
                        <div
                            onClick={() =>
                                props.handleDelete(props.testIndex)
                            }
                            className="ml-2 border-2 border-red-500 text-red-500 hover:bg-red-500 hover:text-white px-3 py-1 rounded cursor-pointer"
                        >
                            <svg
                                className="w-6 h-6 py-1"
                                viewBox="0 0 24 24"
                                width="24"
                                height="24"
                                stroke="currentColor"
                                strokeWidth="2"
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            >
                                <path d="M3 6L5 6 21 6M19 6v14a2 2 0 01-2 2H7a2 2 0 01-2-2V6m3 0V4a2 2 0 012-2h4a2 2 0 012 2v2" />
                            </svg>
                        </div>
                        <div
                            onClick={() =>
                                props.toggleModal(
                                    props.index,
                                    props.value
                                )
                            }
                            className="ml-2 border-2 border-blue-500 text-blue-500 hover:bg-blue-500 hover:text-white px-3 py-1 rounded cursor-pointer"
                        >
                            Edit
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-full">
                <div className="pl-20 w-full">
                    <SortableSubLinksList
                        collection={props.index}
                        parentIndex={props.testIndex}
                    />
                </div>
            </div>
        </div>
    )
})

function mapStateToProps(state) {
    return {
        menu: state.menu,
    }
}

export default withRouter(connect(mapStateToProps)(SortableLinksList))
