import React from 'react'
import Layout from '../../components/Layout'
import { withRouter } from 'react-router-dom'
import { getAd, updateAd } from '../../api/ads'
import Button from '../../components/ui/Button'
import { getLocations } from '../../api/locations'
import ImagePickerModal from '../../components/ImagePickerModal'
import config from '../../config'

class Ad extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            site: props.match.params.id,
            adId: props.match.params.adId,
            ad: {},
            locations: [],
            isLoading: true,
            successfulSave: false,
            imagePicker: false,
        }
    }

    componentDidMount() {
        getLocations({ site: this.state.site })
            .then((locations) => {
                let allLocations = locations.data.concat(
                    config.staticAdLocations
                )

                this.setState({
                    locations: allLocations,
                })
            })
            .then(() => {
                // load ad
                getAd(this.state.adId).then((ad) => {
                    console.log(ad)
                    this.setState({
                        ad: ad.data,
                        isLoading: false,
                    })
                })
            })
    }

    handleChange = (event) => {
        const { name, value } = event.target
        let { ad } = this.state
        ad[name] = value
        this.setState({
            ad,
        })
    }

    handleSave = () => {
        updateAd(this.state.ad).then(() => {
            console.log('ad updated')
            this.setState({
                successfulSave: true,
            })
            setTimeout(() => {
                this.setState({
                    successfulSave: false,
                })
            }, 2500)
        })
    }

    handleImageSelect = (imgObject) => {
        this.setState({
            imagePicker: false,
        })

        const { ad } = this.state
        ad.code = `<a href="#"><img src="${imgObject.url}"/></a>`
        this.setState({
            ad,
        })
    }

    render() {
        const {
            ad,
            isLoading,
            locations,
            successfulSave,
            imagePicker,
        } = this.state
        return (
            <Layout>
                <div>
                    {isLoading && <div>Loading...</div>}
                    {!isLoading && (
                        <div>
                            {imagePicker && (
                                <ImagePickerModal
                                    handleImageSelect={
                                        this.handleImageSelect
                                    }
                                />
                            )}
                            <div className="mt-12 mb-2 flex justify-between items-center">
                                <div>
                                    <div className="text-2xl font-semibold">
                                        Edit Ad
                                    </div>
                                    <div className="text-gray-700 text-sm">
                                        Edit currently running ad
                                    </div>
                                </div>
                                <div className="flex items-center flex-row"></div>
                            </div>
                            <div className="bg-white rounded shadow p-4 w-full sm:w-full md:w-2/3">
                                <div className="mb-4">
                                    <input
                                        name="title"
                                        value={ad.title}
                                        placeholder="Title"
                                        onChange={this.handleChange}
                                        className="border-2 border-gray-300 focus:border-blue-500 px-3 py-1 rounded outline-none w-full"
                                    />
                                </div>
                                <div className="mb-4">
                                    <select
                                        name="type"
                                        className="border-2 border-gray-300 focus:border-blue-500 px-3 py-1 rounded outline-none w-full"
                                        onChange={this.handleChange}
                                    >
                                        <option
                                            value={null}
                                            selected={
                                                ad.type === null
                                                    ? true
                                                    : null
                                            }
                                            disabled
                                        >
                                            Select Ad Type
                                        </option>
                                        <option
                                            value={'adunit'}
                                            selected={
                                                ad.type === 'adunit'
                                                    ? true
                                                    : null
                                            }
                                        >
                                            Ad Unit
                                        </option>
                                        <option
                                            value={'code'}
                                            selected={
                                                ad.type === 'code'
                                                    ? true
                                                    : null
                                            }
                                        >
                                            Code
                                        </option>
                                    </select>
                                </div>
                                <div className="mb-4">
                                    {ad.type === 'adunit' && (
                                        <input
                                            name="code"
                                            value={ad.code}
                                            placeholder="Google Ad Unit Name"
                                            onChange={
                                                this.handleChange
                                            }
                                            className="border-2 border-gray-300 focus:border-blue-500 px-3 py-1 rounded outline-none w-full"
                                        />
                                    )}
                                    {ad.type === 'code' && (
                                        <>
                                            <textarea
                                                rows="5"
                                                name="code"
                                                value={ad.code}
                                                placeholder="Code or Image"
                                                onChange={
                                                    this.handleChange
                                                }
                                                className="border-2 border-gray-900 px-3 py-1 rounded outline-none w-full font-mono bg-gray-900 text-gray-300"
                                            />
                                            <div className="text-xs text-gray-600 italic">
                                                Replace the "#" symbol
                                                with your custom link
                                                to have users go to
                                                another website.
                                            </div>
                                        </>
                                    )}
                                    {ad.type === 'code' && (
                                        <div
                                            onClick={() =>
                                                this.setState({
                                                    imagePicker: true,
                                                })
                                            }
                                            className="border-2 border-purple-600 bg-white hover:bg-purple-600 inline-block text-purple-600 px-3 py-1 rounded cursor-pointer hover:text-white"
                                        >
                                            Insert Image
                                        </div>
                                    )}
                                </div>
                                <div className="mb-4">
                                    <select
                                        name="location"
                                        className="border-2 border-gray-300 focus:border-blue-500 px-3 py-1 rounded outline-none w-full"
                                        onChange={this.handleChange}
                                    >
                                        <option
                                            value={null}
                                            selected={
                                                ad.location === null
                                                    ? true
                                                    : null
                                            }
                                            disabled
                                        >
                                            Select Location
                                        </option>
                                        {locations.map((location) => (
                                            <option
                                                selected={
                                                    location.slug ===
                                                    ad.location
                                                }
                                                value={location.slug}
                                            >
                                                {location.title}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div>
                                    <div
                                        onClick={
                                            ad.title !== ''
                                                ? () =>
                                                      this.handleSave()
                                                : null
                                        }
                                        className="border-2 border-blue-500 bg-blue-500 hover:bg-blue-600 hover:border-blue-600 inline-block text-white px-3 py-1 rounded cursor-pointer"
                                    >
                                        Update Ad
                                    </div>
                                </div>
                                {successfulSave && (
                                    <div className="w-full bg-green-500 text-white font-mono mt-4 rounded p-4">
                                        Update Successful!
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </Layout>
        )
    }
}

export default withRouter(Ad)
