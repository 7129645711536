import React from 'react'
import Compressor from 'compressorjs'
import { getMedia, createMediaItem } from '../../api/media'
import s3Upload from '../../utils/s3Upload'
import config from '../../config'

class Upload extends React.Component {
    constructor(props) {
        super(props)
        this.dropzone = React.createRef()
        this.manualFileSelect = React.createRef()
        this.state = {
            tempMedia: '',
            description: '',
            isDraggingOver: false,
            isLoading: false,
            inputKey: Date.now(),
        }
    }

    componentDidMount() {
        // Add event listeners for file drag actions

        this.dropzone.current.addEventListener('click', (event) => {
            if (this.state.tempMedia === '') {
                this.manualFileSelect.current.click()
            }
        })

        this.dropzone.current.addEventListener(
            'dragenter',
            (event) => {
                event.preventDefault()
            }
        )

        this.dropzone.current.addEventListener(
            'dragleave',
            (event) => {
                event.preventDefault()
                this.setState({
                    isDraggingOver: false,
                })
            }
        )

        this.dropzone.current.addEventListener(
            'dragover',
            (event) => {
                event.preventDefault()
                this.setState({
                    isDraggingOver: true,
                })
            }
        )

        this.dropzone.current.addEventListener('drop', (event) => {
            event.preventDefault()
            this.setState({
                isDraggingOver: false,
            })
            var file = event.dataTransfer.files[0]
            this.handleFileDrop(file, true)
        })
    }

    handleFileSelect = (event) => {
        var file = event.target.files[0]
        this.handleFileDrop(file, false)
    }

    previewMedia = (mediaFile) => {
        if (mediaFile) {
            // Creates blob url so we can show user preview of image
            return URL.createObjectURL(mediaFile)
        } else {
            console.error({
                type: 'Error',
                functionName: 'previewMedia',
                message:
                    'No file object was provided to the function',
            })
            return null
        }
    }

    handleFileDrop = (event, dragged) => {
        let _this = this

        // set temp file
        var file = event

        // Set max size, this is 10MB
        let maxSize = 10485760
        let quality = 0.6

        // Get file extension
        var fileExtension = file.name
            .split('.')
            [file.name.split('.').length - 1].toLowerCase()

        // Get file size
        var fileSize = file.size

        // set file name
        this.setState({
            description: file.name,
        })

        let tempMedia = new Image()

        tempMedia.src = window.URL.createObjectURL(file)

        // checks image dimensions to make sure its big enough
        tempMedia.onload = function () {
            let width = tempMedia.naturalWidth
            let height = tempMedia.naturalHeight

            const minHeight = 250
            const minWidth = 250

            window.URL.revokeObjectURL(tempMedia.src)

            // If file extension is a png, jpg, or jpeg (the diff between the jpgs may not be needed)
            if (
                fileExtension == 'png' ||
                fileExtension == 'jpg' ||
                fileExtension == 'jpeg'
            ) {
                // The file is an image, now lets check sizes
                // Set max size, this is 10MB
                if (fileSize < maxSize) {
                    new Compressor(file, {
                        quality: quality,
                        success(result) {
                            // file is less than maxSize, continue on
                            _this.setState({
                                tempMedia: result,
                            })
                        },
                        error(err) {
                            alert(err)
                        },
                    })
                } else {
                    // file was over maxSize, give user error
                    window.alert(
                        'Error, max image size exceeded. Image must be less than 10 MB. Try uploading a smaller image.'
                    )
                }
            } else {
                // file type isn't a png, jpg, or jpeg
                window.alert(
                    'Error, file type is not allowed. Must be a png or jpeg. Please try again.'
                )
            }
        }
    }

    handleSubmit = () => {
        if (this.state.tempMedia) {
            // set loading
            this.setState({
                isLoading: true,
            })

            const description = this.state.description

            // upload image to s3, and then to post api database
            this.uploadToS3().then((images) => {
                // image object that will be posted to db
                let image = {
                    description: description,
                    attachment: images[0].key,
                    attachment_thumbnail: images[1].key,
                    type: 'image',
                }

                createMediaItem(image).then(() => {
                    this.setState({
                        isLoading: false,
                        tempMedia: '',
                        description: '',
                    })

                    this.props.getMedia()
                })
            })
        }
    }

    uploadToS3 = async () => {
        const attachment = await s3Upload(this.state.tempMedia)
        return attachment
    }

    handleChange = (event) => {
        const { name, value } = event.target
        this.setState({
            [name]: value,
        })
    }

    render() {
        const {
            tempMedia,
            description,
            isLoading,
            inputKey,
        } = this.state
        return (
            <div>
                <div className="mt-12 mb-2 flex justify-between items-center">
                    <div>
                        <div className="text-2xl font-semibold">
                            Upload
                        </div>
                        <div className="text-gray-700 text-sm">
                            Upload a new image. Minimum size is
                            250x250 pixels.
                        </div>
                    </div>
                    <div className="flex items-center flex-row"></div>
                </div>
                {tempMedia === '' && (
                    <div
                        ref={this.dropzone}
                        className={`bg-gray-200 flex items-center justify-center m-1 border-4 border-gray-200 hover:border-blue-500 border-dashed cursor-pointer h-64 rounded ${
                            this.state.isDraggingOver
                                ? 'border-blue-500 border-dashed'
                                : null
                        }`}
                    >
                        <div className="relative">
                            <input
                                key={inputKey}
                                type="file"
                                style={{ display: 'none' }}
                                ref={this.manualFileSelect}
                                onChange={this.handleFileSelect}
                            />
                            <div className="font-mono text-gray-700">
                                Drop media item here or click to
                                upload
                            </div>
                        </div>
                    </div>
                )}
                {tempMedia !== '' && (
                    <div className="w-full sm:w-full md:w-2/3 rounded overflow-auto shadow bg-white flex flex-row">
                        <div className="w-1/2">
                            <img
                                src={this.previewMedia(tempMedia)}
                                className={`bg-cover w-full ${
                                    isLoading ? 'opacity-50' : null
                                }`}
                            />
                        </div>
                        <div className="w-1/2 p-4">
                            <div>Media Description</div>
                            <div className="mb-4">
                                <input
                                    name="description"
                                    value={description}
                                    placeholder="Image Description"
                                    disabled={
                                        this.state.isLoading
                                            ? true
                                            : false
                                    }
                                    onChange={this.handleChange}
                                    className="border-2 border-gray-300 focus:border-blue-500 px-3 py-1 rounded outline-none w-full"
                                />
                            </div>
                            <div>
                                <div
                                    onClick={
                                        isLoading
                                            ? null
                                            : this.handleSubmit
                                    }
                                    className={`border-2 border-blue-500 bg-blue-500 hover:bg-blue-600 hover:border-blue-600 inline-block text-white px-3 py-1 rounded cursor-pointer ${
                                        isLoading
                                            ? 'cursor-not-allowed opacity-50'
                                            : null
                                    }`}
                                >
                                    {isLoading
                                        ? 'Loading...'
                                        : 'Upload'}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        )
    }
}

export default Upload
