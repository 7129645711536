import React from 'react'
import EditorJS from '@editorjs/editorjs'
import ImagePickerModal from './ImagePickerModal'
import { connect } from 'react-redux'
import {
    SET_EDITOR_API,
    SET_IMAGE_PICKER,
    SET_SELECTED_IMAGE,
} from '../actions/constants'
import config from '../config'

//plugins
import Header from '@editorjs/header'
import RawTool from '@editorjs/raw'
import List from '@editorjs/list'
import Embed from '@editorjs/embed'
import SimpleImage from '@editorjs/simple-image'
import ImageLibrary from './simple-image/image-library'
import CenterText from './center-text/center-text'
import Delimiter from '@editorjs/delimiter'

class Editor extends React.Component {
    constructor(props) {
        super(props)

        this.editor = new EditorJS({
            /**
             * Id of Element that should contain Editor instance
             */
            holder: 'codex-editor',
            /**
             * Previously saved data that should be rendered
             */
            tools: {
                header: Header,
                raw: RawTool,
                list: {
                    class: List,
                    inlineToolbar: true,
                },
                embed: {
                    class: Embed,
                    inlineToolbar: true,
                    config: {
                        services: {
                            youtube: true,
                        },
                    },
                },
                image: {
                    class: SimpleImage,
                    inlineToolbar: true,
                },
                imageLibrary: ImageLibrary,
                center: {
                    class: CenterText,
                    inlineToolbar: true,
                },
                delimiter: Delimiter,
            },
            data: {
                blocks: JSON.parse(props.blocks) || [],
                time: 12345,
                version: '123',
            },
            placeholder: 'Let`s write an awesome story!',
            /**
             * onReady callback
             */
            onReady: () => {
                console.log('Editor.js is ready to work!')
            },
            /**
             * onChange callback
             */
            onChange: () => {
                // pass this along to paretnt to get blocks
                this.save()
            },
        })
    }

    setEditorApi = () => {
        console.log('EDITOR')
        console.log(this.editor)
        this.props.dispatch({
            type: SET_EDITOR_API,
            payload: {
                editorAPI: this.editor,
            },
        })
    }

    save = () => {
        this.editor
            .save()
            .then((outputData) => {
                console.log(outputData)
                this.props.autoSave(outputData.blocks)
            })
            .catch((error) => {
                console.log('Saving failed: ', error)
            })
    }

    closeImagePicker = () => {
        this.props.dispatch({
            type: SET_IMAGE_PICKER,
            payload: {
                imagePicker: false,
            },
        })

        this.props.dispatch({
            type: SET_EDITOR_API,
            payload: {
                editorAPI: null,
            },
        })
    }

    handleImageSelect = (image) => {
        // this.editor.__proto__.blocks.insert("image", {
        //     url: url,
        // });
        // console.log("EDITOR_API")
        // console.log(this.props.editorAPI)
        // this.props.editorAPI.blocks.insert("image", {
        //     url: url,
        // });

        console.log(this.props.editorAPI)

        this.editor.__proto__.blocks.insert(
            'image',
            {
                url: image.url ? image.url : `${image.url}`,
                withBackground: true,
                caption: image.description,
            },
            null,
            this.props.editorAPI
        )

        this.closeImagePicker()
        // this.props.dispatch({
        //     type: SET_SELECTED_IMAGE,
        //     payload: {
        //         selectedImage: url
        //     }
        // })
    }

    render() {
        return (
            <>
                {this.props.imagePicker && (
                    <ImagePickerModal
                        closeImagePicker={this.closeImagePicker}
                        handleMediaSelect={this.handleImageSelect}
                    />
                )}
                <div id="codex-editor" className="codex-editor" />
            </>
        )
    }
}

const mapStateToProps = (state) => {
    console.log(state.imagePicker)
    return {
        imagePicker: state.imagePicker,
        editorAPI: state.editorAPI,
    }
}

export default connect(mapStateToProps)(Editor)
