import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import Button from './ui/Button'
import { getAds, deleteAd, createAd, updateAd } from '../api/ads'
import {
    SortableContainer,
    SortableElement,
    SortableHandle,
} from 'react-sortable-hoc'
import arrayMove from 'array-move'
import { connect } from 'react-redux'
import {
    REMOVE_AD,
    UPDATE_AD_ORDER,
    SET_ADS,
} from '../actions/constants'

class SortableAdsList extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
        console.log(props.location)
    }

    updateAds = async () => {
        // udpates all ads by id

        const updatedAds = await Promise.all(
            this.props.adlocations[this.props.location].map(
                (ad, index) => {
                    // ad order to each ad
                    const order = { order: index }
                    const newAd = Object.assign(ad, order)

                    updateAd(newAd).then((data) => {
                        return data.data
                    })
                }
            )
        )

        // const updatedAds = await Promise.all(this.props.ads.filter((ad,index) => {
        //     if(ad.location === this.props.location) {

        //         // ad order to each ad
        //         // const order = { order: index };
        //         // const newAd = Object.assign(ad,order);

        //         updateAd(ad).then((data) => {
        //             return data
        //         });

        //     }
        // }))

        return updatedAds

        // AdsApi.UpdateAd(this.state.site,id,data).then(ad => {
        //     console.log('saved!')
        // }).catch(err => {
        //     window.alert('Error saving ad')
        // })
    }

    onSortEnd = ({ oldIndex, newIndex }) => {
        const moveAds = arrayMove(
            this.props.adlocations[this.props.location],
            oldIndex,
            newIndex
        )
        console.log(moveAds)
        // const moveAds = arrayMove(this.props.ads,oldIndex,newIndex);

        // let adsByLocation = this.props.ads.filter(ad => ad.location === this.props.location);

        // console.log(`item being moved ${adsByLocation[oldIndex].title}`);
        // console.log(`item being moved ${adsByLocation[newIndex].title}`);

        // adsByLocation[oldIndex].order = newIndex;
        // adsByLocation[newIndex].order = oldIndex;

        // console.log(adsByLocation)

        this.props.dispatch({
            type: UPDATE_AD_ORDER,
            payload: {
                location: this.props.location,
                ads: moveAds,
            },
        })

        this.updateAds().then(() => {
            console.log('ads have been reordered')
        })

        // ad order to each ad
        // const order = { order: index };
        // const newAd = Object.assign(ad,order);

        // this.props.dispatch({
        //     type: UPDATE_AD_ORDER,
        //     payload: {
        //         ads:moveAds
        //     }
        // })

        // this.updateAds().then(() => {
        //     console.log('ads have been updated!')
        // })
    }

    filterAdsByCategory = () => {
        this.props.ads.filter((ad) => {
            if (ad.location === this.props.location) {
                return ad
            }
        })
    }

    handleDelete = (ad) => {
        // message to ask user
        const message = `Are you sure you want to delete "${ad.title}"? This cannot be reversed.`

        // ask user to confirm before deleting
        if (window.confirm(message)) {
            deleteAd(ad).then(() => {
                this.props.dispatch({
                    type: REMOVE_AD,
                    payload: {
                        ad,
                    },
                })
            })
        }
    }

    render() {
        return (
            <div>
                <SortableList
                    items={
                        this.props.adlocations[this.props.location]
                    }
                    onSortEnd={this.onSortEnd}
                    useDragHandle={true}
                    handleDelete={this.handleDelete}
                />
            </div>
        )
    }
}

const SortableList = SortableContainer((props) => {
    return (
        <div className="bg-gray-100 rounded overflow-hidden">
            {props.items.length > 0 &&
                props.items.map((value, index) => (
                    <SortableItem
                        key={`item-${index}`}
                        index={index}
                        ad={value}
                        handleDelete={props.handleDelete}
                    />
                ))}
        </div>
    )
})

const DragHandle = SortableHandle(() => (
    <svg
        className="w-5 h-5 text-gray-700 mr-4 cursor-move"
        viewBox="0 0 24 24"
        width="24"
        height="24"
        stroke="currentColor"
        strokeWidth="2"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
    >
        <path d="M5 9L2 12 5 15M9 5L12 2 15 5M15 19L12 22 9 19M19 9L22 12 19 15M2 12L22 12M12 2L12 22" />
    </svg>
))

const SortableItem = SortableElement((props) => {
    return (
        <div className="flex w-full p-4 hover:bg-orange-100 bg-white">
            <div className="flex flex-row w-full items-center justify-between">
                <div className="flex flex-row items-center">
                    <DragHandle></DragHandle>
                    <div className="">{props.ad.title}</div>
                </div>
                <div className="flex items-center">
                    <div
                        onClick={() => props.handleDelete(props.ad)}
                        className="ml-2 border-2 border-red-500 text-red-500 hover:bg-red-500 hover:text-white px-3 py-1 rounded cursor-pointer"
                    >
                        <svg
                            className="w-6 h-6 py-1"
                            viewBox="0 0 24 24"
                            width="24"
                            height="24"
                            stroke="currentColor"
                            strokeWidth="2"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        >
                            <path d="M3 6L5 6 21 6M19 6v14a2 2 0 01-2 2H7a2 2 0 01-2-2V6m3 0V4a2 2 0 012-2h4a2 2 0 012 2v2" />
                        </svg>
                    </div>
                    <Link
                        to={`/dashboard/${props.ad.site}/ads/${props.ad.id}`}
                        className="ml-2 border-2 border-blue-500 text-blue-500 hover:bg-blue-500 hover:text-white px-3 py-1 rounded cursor-pointer"
                    >
                        Edit
                    </Link>
                </div>
            </div>
        </div>
    )
})

function mapStateToProps(state) {
    return {
        ads: state.ads,
        adlocations: state.adlocations,
    }
}

export default connect(mapStateToProps)(SortableAdsList)
