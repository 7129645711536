import API, { api_key } from './api'

export const getCategories = async (query) => {
    try {
        const response = await API.get('/categories', {
            params: {
                ...query,
            },
        })

        return response.data
    } catch (err) {
        return {
            message: 'Error in categories api helper',
            error: err,
        }
    }
}

export const createCategory = async (category) => {
    try {
        const response = await API.post(
            `/categories`,
            {
                title: category.title,
                site: category.site,
            },
            {
                params: {
                    ...api_key,
                },
            }
        )

        return response.data
    } catch (err) {
        return {
            message: 'Error in categories api helper',
            error: err,
        }
    }
}

export const deleteCategory = async (category) => {
    try {
        const response = await API.delete(
            `/categories/${category.id}`,
            {
                params: {
                    ...api_key,
                },
            }
        )

        return response.data
    } catch (err) {
        return {
            message: 'Error in categories api helper',
            error: err,
        }
    }
}
