import API, { api_key } from './api'

export const getMenu = async (query) => {
    try {
        const response = await API.get(`/menus`, {
            params: {
                site: query.site,
            },
        })

        console.log('MENU FROM API')
        console.log(response.data)

        return response.data
    } catch (err) {
        return {
            message: 'Error in menu api helper',
            error: err,
        }
    }
}

export const createMenu = async (site) => {
    try {
        const response = await API.post(
            `/menus`,
            {
                site: site,
                links: [],
            },
            {
                params: {
                    ...api_key,
                },
            }
        )

        return response.data
    } catch (err) {
        return {
            message: 'Error in menu api helper',
            error: err,
        }
    }
}

export const updateMenu = async (menu) => {
    try {
        const response = await API.put(
            `/menus/${menu.id}`,
            {
                links: menu.links,
            },
            {
                params: {
                    ...api_key,
                },
            }
        )

        return response.data
    } catch (err) {
        return {
            message: 'Error in menu api helper',
            error: err,
        }
    }
}
