import { Storage } from "aws-amplify";
import AWS from 'aws-sdk';

const generateThumbnail = async (file) => {
	let img = document.createElement('img');
	img.src = await new Promise(resolve => {
		let reader = new FileReader();
		reader.onload = (e) => resolve(e.target.result)
		reader.readAsDataURL(file)
	})
	await new Promise(resolve => img.onload = resolve);
	let canvas = document.createElement('canvas')
	let ctx = canvas.getContext('2d')
	ctx.drawImage(img, 0, 0)
	let MAX_WIDTH = 200;
	let MAX_HEIGHT = 200;
	let width = img.naturalWidth;
	let height = img.naturalHeight;
	if (width > height) {
		if (width > MAX_WIDTH) {
			height *= MAX_WIDTH / width;
			width = MAX_WIDTH;
		}
	} else {
		if (height > MAX_HEIGHT) {
			width *= MAX_HEIGHT / height;
			height = MAX_HEIGHT;
		}
	}
	canvas.width = width;
	canvas.height = height;
	ctx = canvas.getContext("2d");
	ctx.drawImage(img, 0, 0, width, height);
	let result = await new Promise(resolve => {
		canvas.toBlob(resolve, 'image/jpeg', 1)
		// canvas.toDataURL("image/jpeg")
	})
	return result
}

export default async function s3Upload(file) {

	// var accessKeyId = 'LWMJTZYGW7IDVTEOVLR';
	// var secretAccessKey = 'ojfB6V/M8SZy/cDWcj7Nv5SFOHECkIzu7lDhv+oPA3M';
	// var region = 'nyc3';  // New York region by default

	// var spacesEndpoint = new AWS.Endpoint(region + '.digitaloceanspaces.com');
	// var s3 = new AWS.S3({
	// 	endpoint: spacesEndpoint,
	// 	accessKeyId: accessKeyId,
	// 	secretAccessKey: secretAccessKey
	// });

	// var bucketName = 'eaglepost';
	// var filePath = '/tmp/myFile.txt';
	// var params = {
	// 	Bucket: bucketName,
	// 	Key: 'test.txt',
	// 	Body: 'test'
	// };

	// var options = {
	// 	partSize: 10 * 1024 * 1024, // 10 MB
	// 	queueSize: 10
	// };

	// s3.upload(params, options, function (err, data) {
	// 	if (!err) {
	// 		console.log(data); // successful response
	// 	} else {
	// 		console.log(err); // an error occurred
	// 	}
	// });

	const date = new Date();
	const year = date.getFullYear();
	const month = date.getMonth() + 1;
	const expirationDate = date.getTime() + 31557600 //one year in seconds
	const fileExtension = file.type.split('/').pop();

	const filename = `${year}/${month}/${Date.now()}.${fileExtension}`;
	const thumbnail = `${year}/${month}/${Date.now()}-thumbnail.${fileExtension}`;


	// regular stored image
	const stored = await Storage.put(filename, file, {
		contentType: file.type,
		cacheControl: 'max-age=30758400',
		expires: expirationDate,
		contentEncoding: 'gzip',
		metadata: {
			contentEncoding: 'gzip',
			expires: expirationDate.toString()
		}
	});
	//----------------------

	// thumbnail stored image

	const generated_thumb = await generateThumbnail(file)

	const stored_thumb = await Storage.put(thumbnail, generated_thumb, {
		contentType: 'jpeg',
		cacheControl: 'max-age=30758400',
		expires: expirationDate,
		contentEncoding: 'gzip',
		metadata: {
			contentEncoding: 'gzip',
			expires: expirationDate.toString()
		}
	});
	//----------------------

	// return stored.key;

	return Promise.all([stored, stored_thumb]).then(images => {
		console.log(images)
		// return both images
		return images
	})
}