import React from 'react'
import Layout from '../components/Layout'
import config from '../config'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'

const Dashboard = () => {
    const { sites, version } = config
    const user = useSelector((state) => state.user)
    return (
        <Layout>
            <div>
                <div className="mb-6">
                    <div className="text-2xl font-semibold">
                        Select Site
                    </div>
                    <div className="text-gray-700 text-sm">
                        Select a site to manage posts, pages, ads, ect
                    </div>
                </div>
                <div className="flex flex-wrap rounded overflow-hidden shadow">
                    {sites.map((site) => (
                        <Link
                            to={`/dashboard/${site.value}`}
                            key={site.value}
                            className="w-full"
                        >
                            <div className="p-4 bg-white hover:bg-orange-200">
                                {site.title}
                            </div>
                        </Link>
                    ))}
                </div>
                <div className="my-12 flex flex-wrap justify-between">
                    <div className="w-full mb-8">
                        <div className="text-xl">Welcome!</div>
                        <div className="text-gray-700 leading-relaxed">
                            You are signed in as{' '}
                            {user.attributes
                                ? user.attributes.email
                                : null}
                        </div>
                    </div>
                    <div className="w-full">
                        <div className="text-xl">Version</div>
                        <div className="text-gray-700 leading-relaxed">
                            {version}
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Dashboard
