import React from 'react'
import { Link, withRouter } from 'react-router-dom'

const CurrentlyEditing = (props) => {

    let defaultRoute = 'posts'
    let backRoute = props.backRoute || defaultRoute

    return (
        <div className="w-screen h-screen flex justify-center items-center">
            <div className="flex flex-col justify-cente antialiased font-mono w-full sm:w-full md:w-1/2 p-4">
                <div>
                    <svg className="w-16 h-16 mx-auto text-red-800" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1.5" fill="none" strokeLinecap="round" strokeLinejoin="round"><path d="M7.86 2L16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2zM12 8L12 12M12 16L12.01 16" /></svg>
                </div>
                <div className="text-center text-2xl font-bold mt-6">
                    Whoa there
                </div>
                <div className="text-center text-gray-700">
                    This post is currently being edited by <span className="font-semibold">{props.email}</span>. You can take over this post,
                    but be aware that undesirable results may occur and changes may be lost.
                </div>
                <div className="flex flex-between items-center justify-center mt-6">
                    <Link to={`/dashboard/${props.match.params.id}/${backRoute}`} className="border-2 px-4 py-2 outline-none hover:border-blue-500 mr-4 flex flex-row items-center"><span>
                        <svg className="w-4 h-4 mr-2 text-gray-600" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round"><path d="M19 12L5 12M12 19L5 12 12 5" /></svg>
                    </span>Go back</Link>
                    <button onClick={props.takeOverEditing} className="border-2 px-4 py-2 outline-none hover:border-blue-500 flex items-center">
                        <svg className="w-4 h-4 mr-2 text-gray-600" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" ><path d="M12 20h9M16.5 3.5a2.121 2.121 0 013 3L7 19l-4 1 1-4L16.5 3.5z" /></svg>
                        Take over
                    </button>
                </div>
            </div>
        </div>
    )
}

export default withRouter(CurrentlyEditing)