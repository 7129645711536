import React, { useState, useEffect } from 'react'
import Layout from '../../components/Layout'
import { Link, Redirect, useParams } from 'react-router-dom'
import { getPages, createPage } from '../../api/pages'
import config from '../../config'
import { useSelector } from 'react-redux'
import { createAd } from '../../api/ads'

const Pages = () => {
    const user = useSelector((state) => state.user)
    let { id: siteId } = useParams()
    const [site, setSite] = useState({})
    const [isLoading, setIsLoading] = useState(true)
    const [pages, setPages] = useState([])
    const [meta, setMeta] = useState({})
    const [toPage, setToPage] = useState(false)
    const [newPageId, setNewPageId] = useState(null)
    const [searchQuery, setSearchQuery] = useState('')
    const [currentPage, setCurrentPage] = useState(null)

    useEffect(() => {
        getSite(siteId)

        fetchPages({
            limit: 50,
            site: siteId,
        })
    }, [siteId])

    /**
     * if current page is changed then fire search
     */
    useEffect(() => {
        handleSearch()
    }, [currentPage])

    const getSite = (siteId) => {
        config.sites.find((site) => {
            if (site.value === siteId) {
                setSite(site)
            }
        })
    }

    const fetchPages = async (query) => {
        try {
            const pages = await getPages(query)
            setPages(pages.data)
            setMeta(pages.meta)
            setIsLoading(false)
        } catch (err) {
            console.error(err)
            alert(
                'Error, something went wrong getting posts \n ' + err
            )
        }
    }

    const handlePageCreate = async () => {
        try {
            const newPage = await createPage({
                site: site.value,
            })

            setNewPageId(newPage.data.id)
            setToPage(true)
        } catch (err) {
            console.log(err)
            alert(
                `Error, something went wrong creating the page \n ${err}`
            )
        }
    }

    const handleSearchReturn = (event) => {
        let code = event.keyCode || event.which
        if (code === 13) {
            handleSearch()
        }
    }

    const handleSearch = () => {
        setIsLoading(true)

        /**
         * reset pages
         */
        setPages([])

        /**
         * Create new search object to use with api and qs
         */
        let search_query = {
            ...(searchQuery.length
                ? {
                      search: searchQuery,
                  }
                : null),
            ...(currentPage !== null ? { page: currentPage } : null),
        }

        /**
         * Fetches pages with search query
         */
        fetchPages({
            ...search_query,
            limit: 50,
            site: siteId,
        })
    }

    if (toPage) {
        return (
            <Redirect
                to={`/dashboard/${site.value}/pages/${newPageId}`}
            />
        )
    }

    return (
        <Layout>
            <div className="mb-12">
                <div className="flex justify-between items-center mb-4">
                    <div>
                        <div className="text-2xl font-semibold">
                            All Pages
                        </div>
                        <div className="text-gray-700 text-sm">
                            Manage all pages and search
                        </div>
                    </div>
                    <div>
                        <div
                            onClick={() => handlePageCreate()}
                            className="flex flex-row items-center bg-green-500 hover:bg-green-600 rounded-lg cursor-pointer px-3 py-2 text-white"
                        >
                            <svg
                                className="w-6 h-6 mr-2"
                                width="24"
                                height="24"
                                stroke="currentColor"
                                strokeWidth="2"
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            >
                                <path d="M12 5L12 19M5 12L19 12" />
                            </svg>
                            <span>New Page</span>
                        </div>
                    </div>
                </div>

                <div className="flex flex-row flex-wrap justify-between items-end mb-6">
                    <div className="w-full md:w-4/5 flex flex-row flex-wrap mb-2 md:mb-0">
                        <div className="w-full md:w-1/3">
                            <label className="uppercase text-xs font-bold text-gray-700">
                                Search
                            </label>
                            <input
                                defaultValue={searchQuery}
                                onChange={(event) =>
                                    setSearchQuery(event.target.value)
                                }
                                placeholder="Search Posts"
                                onKeyPress={handleSearchReturn}
                                className="border-2 border-gray-300 focus:border-blue-500 px-3 py-1 rounded outline-none w-full mr-2"
                            />
                        </div>
                    </div>
                    <div className="w-full md:w-1/5 flex justify-end">
                        <button
                            onClick={handleSearch}
                            className="bg-gray-300 hover:bg-gray-400 text-gray-900 px-3 py-2 rounded-lg cursor-pointer"
                        >
                            Update Filter
                        </button>
                    </div>
                </div>

                <div className="flex flex-wrap rounded overflow-hidden shadow mb-8 bg-white">
                    <div className="w-full p-4 flex flex-row justify-between font-bold border-b-2 border-gray-100">
                        <div className="w-1/2">Title</div>
                        <div className="w-1/2 flex justify-end"></div>
                    </div>
                    {isLoading && (
                        <div className="p-4 text-gray-800">
                            Loading...
                        </div>
                    )}
                    {!isLoading &&
                        pages &&
                        pages.map((page) => (
                            <Link
                                to={`/dashboard/${site.value}/pages/${page.id}`}
                                className="w-full p-4 hover:bg-orange-200 cursor-pointer flex flex-row justify-between"
                                key={page.id}
                            >
                                <div className="w-1/2 truncate flex items-center">
                                    <div>
                                        <div className="text-gray-900 font-semibold antialiased">
                                            {page.title}
                                        </div>
                                        <div className="text-xs flex flex-row">
                                            {page.editing &&
                                                page.editing_by !==
                                                    user.attributes
                                                        .email && (
                                                    <span className="text-red-400 flex flex-row items-center">
                                                        CURRENTLY
                                                        BEING EDITED (
                                                        {page.editing_by.toLowerCase()}
                                                        )
                                                    </span>
                                                )}
                                        </div>
                                    </div>
                                </div>
                                <div className="w-1/2 flex justify-end truncate">
                                    <svg
                                        viewBox="0 0 24 24"
                                        width="24"
                                        height="24"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    >
                                        <polyline points="9 18 15 12 9 6"></polyline>
                                    </svg>
                                </div>
                            </Link>
                        ))}
                </div>
                <Pagination
                    meta={meta}
                    setCurrentPage={setCurrentPage}
                />
            </div>
        </Layout>
    )
}

const Pagination = ({ meta, setCurrentPage }) => (
    <div className="flex justify-between items-center">
        <div>
            {meta.total_results} Results - Page {meta.current_page} of{' '}
            {meta.total_pages}
        </div>
        <div className="flex items-center">
            <div className="mr-4">
                <button
                    disabled={meta.current_page > 1 ? false : true}
                    onClick={() =>
                        setCurrentPage(meta.current_page - 1)
                    }
                    className={`px-3 py-2 bg-gray-300 rounded-lg hover:bg-gray-400 ${
                        meta.current_page > 1
                            ? null
                            : 'cursor-not-allowed opacity-50'
                    } `}
                >
                    Prev
                </button>
            </div>
            <div>
                <button
                    disabled={
                        meta.current_page === meta.total_pages
                            ? true
                            : false
                    }
                    onClick={() =>
                        setCurrentPage(meta.current_page + 1)
                    }
                    className={`px-3 py-2 bg-gray-300 rounded-lg hover:bg-gray-400 ${
                        meta.current_page === meta.total_pages
                            ? 'cursor-not-allowed opacity-50'
                            : null
                    } `}
                >
                    Next
                </button>
            </div>
        </div>
    </div>
)

export default Pages
