import API, { api_key } from './api'

export const getPosts = async (query) => {
    try {
        const response = await API.get(`/posts`, {
            params: {
                ...query,
            },
        })

        return response.data
    } catch (err) {
        return {
            message: 'Error in posts api helper',
            error: err,
        }
    }
}

export const getPost = async (id) => {
    try {
        const response = await API.get(`/posts/${id}`)

        return response.data
    } catch (err) {
        return {
            message: 'Error in posts api helper',
            error: err,
        }
    }
}

export const createPost = async (site) => {
    const default_post = {
        title: 'Untitled',
        sites: [site],
        ad_sidebar_location: 'innerPageSidebar',
    }

    try {
        const response = await API.post(
            `/posts`,
            {
                ...default_post,
            },
            {
                params: {
                    ...api_key,
                },
            }
        )

        return response.data
    } catch (err) {
        return {
            message: 'Error in posts api helper',
            error: err,
        }
    }
}

export const updatePost = async (post) => {
    try {
        const response = await API.put(
            `/posts/${post.id}`,
            {
                title: post.title,
                body: post.body,
                featured_image: post.featured_image,
                featured_image_thumbnail:
                    post.featured_image_thumbnail,
                featured: post.featured,
                status: post.status,
                categories: post.categories,
                sites: post.sites,
                published: post.published,
                ad_sidebar_location: post.ad_sidebar_location,
                editing_by: post.editing_by,
                blocks: post.blocks,
            },
            {
                params: {
                    ...api_key,
                },
            }
        )

        return response.data
    } catch (err) {
        return {
            message: 'Error in posts api helper',
            error: err,
        }
    }
}

export const set_currently_editing = async (
    postId,
    editing_by_value
) => {
    try {
        const response = await API.put(
            `/posts/${postId}`,
            {
                editing_by: editing_by_value,
            },
            {
                params: {
                    ...api_key,
                },
            }
        )

        return response.data
    } catch (err) {
        return {
            message:
                'Error in posts remove currently editing api helper',
            error: err,
        }
    }
}

export const deletePost = async (post, email) => {
    try {
        const response = await API.delete(`/posts/${post.id}`, {
            params: {
                email: email,
                ...api_key,
            },
        })

        return response.data
    } catch (err) {
        return {
            message: 'Error in posts api helper',
            error: err,
        }
    }
}
