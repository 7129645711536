import React, { useState, useEffect } from 'react'
import Layout from '../../components/Layout'
import { Link, withRouter } from 'react-router-dom'
import Button from '../../components/ui/Button'
import { getAds, deleteAd, createAd, updateAd } from '../../api/ads'
import { getLocations } from '../../api/locations'
import {
    SortableContainer,
    SortableElement,
    SortableHandle,
} from 'react-sortable-hoc'
import arrayMove from 'array-move'
import { useDispatch, useSelector } from 'react-redux'
import {
    SET_ADS,
    ADD_AD,
    UPDATE_AD_ORDER,
    SET_LOCATIONS,
} from '../../actions/constants'
import SortableAdsList from '../../components/SortableAdsList'
import ImagePickerModal from '../../components/ImagePickerModal'
import config from '../../config'

const Ads = (props) => {
    let defaultAd = {
        title: '',
        location: null,
        code: '',
        type: null,
        status: 'published',
    }

    const dispatch = useDispatch()
    const ads = useSelector((state) => state.ads)
    const adlocations = useSelector((state) => state.adlocations)
    const [site] = useState(props.match.params.id)
    const [locations, setLocations] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [imagePicker, setImagePicker] = useState(false)
    const [ad, setAd] = useState(defaultAd)

    useEffect(() => {
        getLocations({ site: site })
            .then((locations) => {
                let allLocations = locations.data.concat(
                    config.staticAdLocations
                )

                setLocations(allLocations)

                // set adlocations in redux store
                allLocations.forEach((location) => {
                    dispatch({
                        type: SET_LOCATIONS,
                        payload: {
                            location: location.slug,
                        },
                    })
                })
            })
            .then(() => {
                // gets initial ads
                getAds({ site: site }).then((ads) => {
                    ads.data.forEach((ad) => {
                        dispatch({
                            type: SET_ADS,
                            payload: {
                                ad: ad,
                            },
                        })
                    })

                    setIsLoading(false)
                })
            })
    }, [site])

    const handleImageSelect = (imgObject) => {
        setAd({
            ...ad,
            code: `<a href="#"><img src="${imgObject.url}"/></a>`,
        })
        setImagePicker(false)
    }

    const handleCreate = () => {
        // check to make sure location is selected
        if (!ad.location) return alert('Location must be selected')

        // new ad object
        let newAd = {
            ...ad,
            site: site,
            order: ads ? ads.length + 1 : 0,
        }

        // creates new ad
        createAd(newAd).then((ad) => {
            dispatch({
                type: ADD_AD,
                payload: {
                    ad: ad.data,
                },
            })

            // reset local state
            setAd(defaultAd)
        })
    }

    const handleChange = (event) => {
        const { name, value } = event.target
        setAd({
            ...ad,
            [name]: value,
        })
    }

    return (
        <Layout>
            <div>
                {imagePicker && (
                    <ImagePickerModal
                        handleMediaSelect={handleImageSelect}
                    />
                )}
                <div className="mt-12 mb-2 flex justify-between items-center">
                    <div>
                        <div className="text-2xl font-semibold">
                            Create New Ad
                        </div>
                        <div className="text-gray-700 text-sm">
                            Create a new ad with custom code or a
                            google ad unit
                        </div>
                    </div>
                    <div className="flex items-center flex-row"></div>
                </div>
                <div className="bg-white rounded shadow p-4 w-full sm:w-full md:w-2/3">
                    <div className="mb-4">
                        <input
                            name="title"
                            value={ad.title}
                            placeholder="Title"
                            onChange={handleChange}
                            className="border-2 border-gray-300 focus:border-blue-500 px-3 py-1 rounded outline-none w-full"
                        />
                    </div>
                    <div className="mb-4">
                        <select
                            name="type"
                            className="border-2 border-gray-300 focus:border-blue-500 px-3 py-1 rounded outline-none w-full"
                            onChange={handleChange}
                            selected={ad.type}
                        >
                            <option
                                selected={ad.type ? false : true}
                                disabled
                                defaultChecked
                            >
                                Select Ad Type
                            </option>
                            <option value="adunit">Ad Unit</option>
                            <option value="code">Code</option>
                        </select>
                    </div>
                    <div className="mb-4">
                        {ad.type === 'adunit' && (
                            <input
                                name="code"
                                value={ad.code}
                                placeholder="Google Ad Unit Name"
                                onChange={handleChange}
                                className="border-2 border-gray-300 focus:border-blue-500 px-3 py-1 rounded outline-none w-full"
                            />
                        )}
                        {ad.type === 'code' && (
                            <>
                                <textarea
                                    rows="5"
                                    name="code"
                                    value={ad.code}
                                    placeholder="Code or Image"
                                    onChange={handleChange}
                                    className="border-2 border-gray-900 px-3 py-1 rounded outline-none w-full font-mono bg-gray-900 text-gray-300"
                                />
                                <div className="text-xs text-gray-600 italic">
                                    Replace the "#" symbol with your
                                    custom link to have users go to
                                    another website.
                                </div>
                            </>
                        )}
                        {ad.type === 'code' && (
                            <div
                                onClick={() => setImagePicker(true)}
                                className="border-2 border-purple-600 bg-white hover:bg-purple-600 inline-block text-purple-600 px-3 py-1 rounded cursor-pointer hover:text-white"
                            >
                                Insert Image
                            </div>
                        )}
                    </div>
                    <div className="mb-4">
                        <select
                            name="location"
                            className="border-2 border-gray-300 focus:border-blue-500 px-3 py-1 rounded outline-none w-full"
                            onChange={handleChange}
                            selected={ad.location}
                        >
                            <option
                                selected={ad.location ? false : true}
                                disabled
                                defaultChecked
                            >
                                Select Location
                            </option>
                            {locations.map((location, index) => (
                                <option
                                    key={index}
                                    value={location.slug}
                                >
                                    {location.title}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div>
                        <div
                            onClick={
                                ad.title !== ''
                                    ? () => handleCreate()
                                    : null
                            }
                            className="border-2 border-green-500 bg-green-500 hover:bg-green-600 hover:border-green-600 inline-block text-white px-3 py-1 rounded cursor-pointer"
                        >
                            Create Ad
                        </div>
                    </div>
                </div>

                <div className="mt-12 mb-2 flex justify-between items-center">
                    <div>
                        <div className="text-2xl font-semibold">
                            All Ads
                        </div>
                        <div className="text-gray-700 text-sm">
                            All currently running ads. Drag to reorder
                            placements in each ad location. Dragging
                            ads between different ad locations is not
                            supported.
                        </div>
                    </div>
                    <div className="flex items-center flex-row"></div>
                </div>
                <div className="flex flex-wrap">
                    {isLoading && <div>Loading ads</div>}
                    {!isLoading &&
                        locations &&
                        locations.map((location) => (
                            <div
                                key={location.slug}
                                className="w-full sm:w-full md:w-1/2 pr-0 sm:pr-0 md:pr-4 pb-4"
                            >
                                <div className="bg-white rounded shadow">
                                    <div className="font-bold text-xl p-4">
                                        {location.title}
                                    </div>
                                    <div>
                                        {adlocations[location.slug] &&
                                            adlocations[location.slug]
                                                .length > 0 && (
                                                <SortableAdsList
                                                    location={
                                                        location.slug
                                                    }
                                                />
                                            )}
                                    </div>
                                </div>
                            </div>
                        ))}
                </div>
            </div>
        </Layout>
    )
}

export default withRouter(Ads)
