import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import { useSelector } from 'react-redux'
import config from '../config'

const pathString = (url) => {
    const newPathStringArray = url.split('/')
    return newPathStringArray
}

const generatePath = (pathIndex, currentPath) => {
    const route = pathString(currentPath).filter((path, index) => {
        if (index <= pathIndex) {
            return path
        }
    })
    return route.join('/')
}

const siteObject = (sites, siteValue) => {
    return sites.find((object) => {
        if (object.value === siteValue) {
            return object.title
        }
    })
}

const Breadcrumbs = (props) => {
    const store = useSelector((store) => store)
    const pathStringLength = pathString(props.match.url).length - 1
    return (
        <div className="mb-8 bg-white py-2 px-3 rounded text-gray-900 shadow">
            <div className="flex flex-row">
                {pathString(props.match.url).map((path, index) => {
                    if (index > 0) {
                        return (
                            <div
                                className="flex flex-row"
                                key={index}
                            >
                                <Link
                                    key={index}
                                    to={`/${generatePath(
                                        index,
                                        props.match.url
                                    )}`}
                                    className="mr-2 hover:underline capitalize"
                                >
                                    {index === 2
                                        ? siteObject(
                                              config.sites,
                                              path
                                          ).title
                                        : path}
                                </Link>
                                <div className="mr-2">
                                    {index < pathStringLength && '/'}
                                </div>
                            </div>
                        )
                    } else return null
                })}
            </div>
        </div>
    )
}

export default withRouter(Breadcrumbs)
