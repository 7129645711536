import axios from 'axios'

/**
 * API key to append to requests
 */
export const api_key = {
    api_key: 'ffdsa932hfdsajko3',
}

/**
 * export default url for axios
 */
export default axios.create({
    baseURL: `https://api.burlingtonbeacon.eaglewebservices.com/v3/`,
})
