const config = {
    version: '1.8.3',
    MAX_ATTACHMENT_SIZE: 1000000,
    sites: [
        {
            title: 'Burlington Beacon',
            value: 'burlingtonbeacon',
            url: 'https://burlingtonbeacon.com',
        },
    ],
    s3: {
        REGION: 'us-east-1',
        BUCKET: 'burlington-beacon-media-bucket',
        URL:
            'https://s3.amazonaws.com/burlington-beacon-media-bucket/public/',
        CDN: 'd2eftiauov6o73.cloudfront.net/public/',
    },
    cognito: {
        REGION: 'us-east-1',
        USER_POOL_ID: 'us-east-1_hHDyaSGiy',
        APP_CLIENT_ID: '6v8vh7jml66bachgvb391s5nlf',
        IDENTITY_POOL_ID:
            'us-east-1:313087a4-ef47-44d4-8a37-fe803bc57f6b',
    },
    imageBasePath: 'd2eftiauov6o73.cloudfront.net/public/',
    staticAdLocations: [
        {
            title: 'Home Push Down Ad',
            slug: 'homePushDownAd',
        },
        {
            title: 'Home Popup',
            slug: 'homePopup',
        },
        {
            title: 'Home Below Featured / Top of inner post',
            slug: 'homeBelowFeatured',
        },
        {
            title: 'Home Left Inner Stories',
            slug: 'homeLeftColumn',
        },
        {
            title: 'Home Right Inner Stories',
            slug: 'homeRightColumn',
        },
        {
            title: 'Home Sidebar',
            slug: 'homeSidebar',
        },
        {
            title: 'Inner Page Sidebar',
            slug: 'innerPageSidebar',
        },
        {
            title: 'Home Sports',
            slug: 'homeSports',
        },
        {
            title: 'Home Fourth Category',
            slug: 'homeFourthCategory',
        },
        {
            title: 'Inner Page Above Title',
            slug: 'innerPageAboveTitle',
        },
    ],
    staticPostCategories: [
        {
            title: 'Local',
            slug: 'local',
        },
        {
            title: 'State',
            slug: 'state',
        },
        {
            title: 'Arrests',
            slug: 'arrests',
        },
        {
            title: 'Obituaries',
            slug: 'obituaries',
        },
        {
            title: 'Sports',
            slug: 'sports',
        },
    ],
}

export default config
